import {
  AutoReportMode,
  CommunicationContext,
  FieldDisplayType,
  Module,
  ModulePageItem,
  Operand,
  ReminderEmail,
  Rule,
  SurveyWizardContext,
  WizardTypes
} from 'app/shared/models';
import { ModuleView } from 'app/shared/product-wizard/survey/module-selection/models';
import { ValidationError } from 'app/shared/product-wizard/survey/module-selection/models/survey-wizard-question.model';
import { LayoutType } from 'app/shared/survey-render/models';
import { Actions as ProductWizardActions } from 'app/state/actions/product-wizard.actions';
import {
  Actions as SurveyWizardActions,
  Type as SurveyWizardActionType
} from 'app/state/actions/survey-wizard.actions';
import { cloneDeep } from 'lodash-es';
import { handleProductWizardActions } from './product-wizard-helpers';


enum RuleConditionOperandSource {
  'Question' = 'Question',
}

const OPTIONAL_QUESTIONS_KEY = 'OptionalQuestions';
const todaysDate = new Date();
export const initialState: SurveyWizardContext = {
  wizardType: WizardTypes.survey,
  activeStep: 0,
  productDefinition: null,
  productId: null,
  surveyId: null,
  name: null,
  wasDeactivated: false,
  saving: false,
  modules: [],
  allModules: [],
  index: {
    indexInfo: [],
    indexValidation: [],
    indexes: null,
  },
  participants: {
    participantCount: 0,
    phoneNumberParticipantCount: 0,
    nonPinCodeParticipantCount: 0,
    selectedHierarchy: null,
    groups: [],
    emailParticipantCount: 0,
    perspectivesRespondentsCount: null,
    perspectivesFocusUsersCount: null
  },
  communication: {
    communicationEnabled: true,
    communicationMethod: 'email',
    activationEmail: null,
    hasPinGroups: false,
    pinCodeEmail: null,
    autoReport: true,
    autoReportMode: AutoReportMode.GroupManagers,
    activationSms: null,
    pinCodeSms: null,
    welcomeSms: null,
    previewLanguage: { id: null, code: null },
    reportEmail: null
  },
  schedule: {
    reminderEmails: [],
    directActivation: true,
    startDate: new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate()),
    endDate: new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate() + 14, 23, 59, 0),
    reportDate: getNextBusinessDay(new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate() + 14, 9, 0, 0))
  },
  currentVersion: null,
  unsaved: false
};

export function reducer(state: SurveyWizardContext = initialState, action: SurveyWizardActions | ProductWizardActions) {

  const baseState = handleProductWizardActions(state, action, WizardTypes.survey, initialState);
  if (baseState !== null) {
    return baseState as SurveyWizardContext;
  }
  switch (action.type) {

    case SurveyWizardActionType.UNLOAD_SURVEY_WIZARD: {
      return initialState;
    }

    case SurveyWizardActionType.SETUP_CONTENT_SUCCESS: {
      const allModules = action.payload.flatMap(mcg => mcg.modules);
      const modules = state.modules
        .filter(m => !!allModules.find(am => am.id === m.id)?.isActive)
        .map((m): ModuleView => {
          const moduleMatch = allModules.find(a => a.id === m.id);
          return {
            ...m,
            name: moduleMatch?.name ?? m.name,
            main: !!moduleMatch?.main
          }
        });

      return { ...state, allModules: allModules, modules: modules };
    }

    case SurveyWizardActionType.TOGGLE_MODULE: {
      const currentModules: ModuleView[] = cloneDeep(state.modules);
      if (currentModules.some(x => x.id === action.payload.id)) {
        currentModules.removeEvery(x => x.id === action.payload.id);
      } else {
        currentModules.push(cloneDeep(action.payload));
      }
      hideDuplicateQuestions(currentModules);
      return { ...state, modules: currentModules };
    }

    case SurveyWizardActionType.TOGGLE_EXPAND_MODULE: {
      const currentModules: ModuleView[] = cloneDeep(state.modules);
      let module = currentModules.find(m => m.id === action.payload);
      if (module) {
        module.expanded = !module.expanded;
        return { ...state, modules: currentModules };
      }
      const allModules = cloneDeep(state.allModules);
      module = allModules.find(m => m.id === action.payload);
      module.expanded = !module.expanded;
      return { ...state, allModules: allModules };
    }

    case SurveyWizardActionType.REFRESH_MODULE_RULES: {
      const currentModules: ModuleView[] = cloneDeep(state.modules);
      const itemIndex = currentModules.findIndex(m => m.id === action.payload.id);
      if (itemIndex > -1) {
        const currentRules = action.payload.rules;
        currentModules[itemIndex].rules = currentRules;
      }
      hideDuplicateQuestions(currentModules);
      return { ...state, modules: currentModules };
    }

    case SurveyWizardActionType.ADD_QUESTIONS_TO_MODULE: {
      const currentModules: ModuleView[] = cloneDeep(state.modules);
      const module = currentModules.find(m => m.id === action.payload.id);
      const pageGroup = module.pageGroups.find(pg => pg.pageGroupId === action.payload.pageGroupId);
      let orderIndex = pageGroup?.pages.length ?? 0;
      if (module.key === OPTIONAL_QUESTIONS_KEY) {
        module.totalQuestions = module.totalQuestions + action.payload.questions.length;
      }
      for (const question of action.payload.questions) {
        const pageGroupIndex = module.pageGroups.findIndex(pg => pg.pageGroupId === action.payload.pageGroupId);
        module.pageGroups[pageGroupIndex !== -1 ? pageGroupIndex : 0].pages.push({
          pageId: orderIndex,
          includedInProgress: true,
          order: orderIndex++,
          showAbout: true,
          showChapterProgress: true,
          showNavigation: true,
          showPageProgress: true,
          key: question.pageType as LayoutType,
          pageItems: [{
            companyId: question.companyId,
            hasRules: false,
            key: question.key,
            icon: question.icon,
            type: question.type,
            questionId: question.id,
            text: question.text,
            order: 0,
            customAdded: true,
            excluded: false,
            excludedByRule: false,
            excludedByRuleTarget: false,
            excludedByUser: false,
            moduleOrder: 0,
            displayType: question.displayType as FieldDisplayType[]
          }]
        });
        hideDuplicateQuestions(currentModules);
      }
      return { ...state, modules: currentModules };
    }

    case SurveyWizardActionType.REMOVE_QUESTIONS_FROM_MODULE: {
      const currentModules: ModuleView[] = cloneDeep(state.modules);
      const module = currentModules.find(m => m.id === action.payload.moduleId);
      const pageGroup = module.pageGroups.find(pg => pg.pageGroupId === action.payload.pageGroupId);

      const questionToRemoveIndex = pageGroup.pages
        .findIndex(pg => pg.pageItems.some(p => p.questionId === action.payload.questionId && p.customAdded));

      pageGroup.pages.splice(questionToRemoveIndex, 1)
      if (module.key === OPTIONAL_QUESTIONS_KEY) {
        module.totalQuestions = module.totalQuestions - 1;
      }
      return { ...state, modules: currentModules };
    }

    case SurveyWizardActionType.SORT_MODULES: {
      const currentModules = cloneDeep(state.modules);
      sortModules(currentModules);
      return { ...state, modules: currentModules };
    }

    case SurveyWizardActionType.SET_NAME_SUCCESS: {
      return { ...state, name: action.payload };
    }

    case SurveyWizardActionType.SET_PARTICIPANTS_COUNTS: {
      return {
        ...state, participants: {
          ...state.participants,
          participantCount: action.payload.userCount,
          phoneNumberParticipantCount: action.payload.usersWithPhone,
          nonPinCodeParticipantCount: action.payload.nonPinCodeUserCount,
          emailParticipantCount: action.payload.usersWithEmail,
          perspectivesRespondentsCount: action.payload.perspectivesRespondentsCount,
          perspectivesFocusUsersCount: action.payload.perspectivesFocusUsersCount
        }
      };
    }

    case SurveyWizardActionType.SET_GROUP_SELECTION: {
      return { ...state, participants: { ...state.participants, groupSelection: action.payload } };
    }

    case SurveyWizardActionType.SET_GROUPS: {
      return {
        ...state,
        participants: {
          ...state.participants,
          groups: action.payload
        },
        communication: {
          ...state.communication,
          hasPinGroups: action.payload.filter(g => g.pin).length > 0
        }
      };
    }

    case SurveyWizardActionType.SET_SELECTED_HIERARCHY: {
      return { ...state, participants: { ...state.participants, selectedHierarchy: action.payload } };
    }

    case SurveyWizardActionType.SET_COMMUNICATION_METHOD_SUCCESS: {
      const _state = {
        ...state,
        communication: { ...state.communication, communicationMethod: action.payload },
        schedule: {
          ...state.schedule, reminderEmails: initReminders(state)
        }
      };

      return _state;
    }

    case SurveyWizardActionType.SET_COMMUNICATION_ENABLED: {
      return { ...state, communication: { ...state.communication, communicationEnabled: action.payload } };
    }

    case SurveyWizardActionType.SET_ACTIVATION_EMAIL: {
      return {
        ...state,
        communication: { ...state.communication, activationEmail: action.payload },
        schedule: {
          ...state.schedule, reminderEmails: getReminderEmails(state.schedule.startDate, state.schedule.endDate, action.payload.id)
        }
      };
    }

    case SurveyWizardActionType.SET_ACTIVATION_SMS: {
      return { ...state, communication: { ...state.communication, activationSms: action.payload } };
    }
    case SurveyWizardActionType.SET_PIN_CODE_EMAIL: {
      return { ...state, communication: { ...state.communication, pinCodeEmail: action.payload } };
    }
    case SurveyWizardActionType.SET_PIN_CODE_SMS: {
      return { ...state, communication: { ...state.communication, pinCodeSms: action.payload } };
    }
    case SurveyWizardActionType.SET_PINCODE_LANGUAGE: {
      return { ...state, communication: { ...state.communication, pincodeLanguage: action.payload } };
    }
    case SurveyWizardActionType.SET_PREVIEW_LANGUAGE: {
      return { ...state, communication: { ...state.communication, previewLanguage: action.payload } };
    }
    case SurveyWizardActionType.SET_REPORT_EMAIL: {
      return { ...state, communication: { ...state.communication, reportEmail: action.payload } };
    }
    case SurveyWizardActionType.SET_WELCOME_SMS: {
      return { ...state, communication: { ...state.communication, welcomeSms: action.payload } };
    }
    case SurveyWizardActionType.SET_WELCOME_LANGUAGE: {
      return { ...state, communication: { ...state.communication, welcomeLanguage: action.payload } };
    }
    case SurveyWizardActionType.SET_AUTO_REPORT: {
      return { ...state, communication: { ...state.communication, autoReport: action.payload } };
    }

    case SurveyWizardActionType.INIT_AUTO_REPORT: {
      const product = action.payload.context.productDefinition.definitionObject;
      const autoReportMode = product.survey.autoReportSettings?.mode ?? AutoReportMode.GroupManagers;
      if (state.communication.autoReport !== null) { // already set in draft, don't change
        return { ...state, communication: { ...state.communication, autoReportMode: autoReportMode } };
      }
      const communication = product.steps.find(s => s.stepId === 'communications');
      let autoReport = action.payload.autoReportDefaultValue;
      if (communication && 'autoReport' in communication.settings) {
        autoReport = !!communication.settings.autoReport;
      }

      return { ...state, communication: { ...state.communication, autoReport: autoReport, autoReportMode: autoReportMode } };
    }

    case SurveyWizardActionType.SET_START_DATE: {
      return { ...state, schedule: { ...state.schedule, startDate: action.payload, directActivation: isDirect(action.payload) } };
    }

    case SurveyWizardActionType.SET_END_DATE: {
      return {
        ...state, schedule: {
          ...state.schedule, endDate: action.payload,
          reportDate: action.payload == null ?
            state.schedule.reportDate :
            getNextBusinessDay(new Date(action.payload.getFullYear(), action.payload.getMonth(), action.payload.getDate(), 9, 0, 0)),
          reminderEmails: action.payload == null ?
            [] :
            getReminderEmails(state.schedule.startDate, action.payload, state.communication.activationEmail.id)
        }
      };
    }

    case SurveyWizardActionType.SET_REPORT_DATE: {
      return { ...state, schedule: { ...state.schedule, reportDate: action.payload } };
    }
    case SurveyWizardActionType.SET_DIRECT_REPORT: {
      return { ...state, schedule: { ...state.schedule, directReport: action.payload } };
    }
    case SurveyWizardActionType.SET_REMINDER_EMAILS: {
      return { ...state, schedule: { ...state.schedule, reminderEmails: [...action.payload] } };
    }

    case SurveyWizardActionType.SET_REMINDER_EMAIL: {
      const reminderEmails = [...state.schedule.reminderEmails];
      reminderEmails[action.payload.index] = action.payload.reminderEmail;
      return { ...state, schedule: { ...state.schedule, reminderEmails: reminderEmails } };
    }

    case SurveyWizardActionType.SET_START_TIME: {
      if (action.payload.direct) {
        return { ...state, schedule: { ...state.schedule, directActivation: true } };
      } else {
        const s = state.schedule.startDate;
        return {
          ...state, schedule: {
            ...state.schedule,
            directActivation: false,
            startDate: new Date(
              s.getFullYear(),
              s.getMonth(),
              s.getDate(),
              action.payload.hour,
              action.payload.minute,
              0)
          }
        };
      }

    }

    case SurveyWizardActionType.TOGGLE_QUESTION: {
      const modules = cloneDeep(state.modules);
      toggleQuestion(modules, action.payload.moduleId, action.payload.questionId, null);
      hideDuplicateQuestions(modules);
      return { ...state, modules: modules };
    }

    case SurveyWizardActionType.SET_INDEXES: {
      const indexList = action.payload.filter(i => i.errors === ValidationError.None)
        .map(x => x.id);
      return { ...state, index: { ...state.index, indexes: indexList, indexValidation: action.payload } };
    }

    case SurveyWizardActionType.GET_INDEX_INFO_SUCCESS: {
      return { ...state, index: { ...state.index, indexInfo: action.payload } };
    }

    case SurveyWizardActionType.SET_SURVEY_ID: {
      return { ...state, surveyId: action.payload };
    }
    default: {
      return state;
    }
  }
}

export const getScheduleContext = (state: SurveyWizardContext) => state.schedule;
export const getCommunicationContext = (state: SurveyWizardContext) => state.communication;
export const getParticipantContext = (state: SurveyWizardContext) => state.participants;
export const canAddReminders = (state: CommunicationContext) => state.communicationMethod === 'email';

function toggleQuestion(modules: Module[], moduleId: number, questionId: number, pageItemId?: number) {
  let oldExcludedValue = false;
  let filteredModules = modules.filter(x => x.id === moduleId);
  if (!filteredModules.length) {
    filteredModules = modules;
  }

  for (const m of filteredModules) {
    const targets: number[] = [];
    for (const pg of m.pageGroups) {
      for (const p of pg.pages) {
        // Toggle the question
        p.pageItems
          .forEach(pi => {
            if ((pageItemId == null || pi.pageItemId === pageItemId) && pi.questionId === questionId) {
              pi.excluded = !pi.excluded;
              pi.excludedByUser = false;
            }
            // If it is not a duplicate but the item is missing, then it has been hidden by the user
            if ((pageItemId == null) && pi.questionId === questionId) {
              pi.excludedByUser = !pi.excludedByUser;
            }

            // Save old excluded value
            if (pi.questionId === questionId) {
              if (pi.excluded && pi.excludedByRuleTarget) {
                oldExcludedValue = true;
              } else {
                oldExcludedValue = false;
              }
            }

            // Warn if question includes a rule and target rules
            for (const rule of m.rules) {
              const operand = getOperand(rule);
              if (pi.questionId === questionId && (operand.source === RuleConditionOperandSource.Question
                && operand.sourceId === questionId)) {
                pi.excludedByRule = !pi.excludedByRule;
                for (const target of rule.targets) {
                  targets.push(target.targetId);
                }
              }
            }
            // Check if question is included in rule targets
            for (const targetId of targets) {
              if (targetId === pi.questionId) {
                // Reset toggle of already excluded questions
                if (oldExcludedValue && pi.excluded && pi.excludedByRuleTarget) {
                  pi.excluded = false;
                } else {
                  pi.excludedByRuleTarget = !pi.excludedByRuleTarget;
                }

                if (pi.excluded && pi.excludedByRuleTarget) {
                  pi.excluded = false;
                  if (pi.excludedByRule) {
                    pi.excludedByRule = false;
                  }
                }

                // Recursive check if target also has rule and target
                toggleQuestion(modules, moduleId, targetId);

              }
            }
          });
      }
    }
  }
}

function getOperand(rule: Rule): Operand {
  if (rule.condition.conditions.length > 0) {
    if (rule.condition.conditions[0].operand1.source === RuleConditionOperandSource.Question) {
      return rule.condition.conditions[0].operand1;
    }
    return rule.condition.conditions[0].operand2;
  } else {
    if (rule.condition.operand1.source === RuleConditionOperandSource.Question) {
      return rule.condition.operand1;
    }
    return rule.condition.operand2;
  }
}
function hideDuplicateQuestions(modules: Module[]) {
  const resetDuplicatesGroupOnKey: Grouping<ModulePageItem>[] = modules
    .reduce((am, cm) => [...am, ...cm.pageGroups
      .reduce((apg, cpg) => [...apg, ...cpg.pages
        .reduce((ap, cp) => [...ap, ...cp.pageItems.filter(pi => pi.questionId != null && pi.excluded && !pi.excludedByUser)
        ], []).map((obj: ModulePageItem): ModulePageItem => ({ ...obj, moduleId: cm.id, questionPriority: cm.questionPriority, moduleOrder: cm.order }))
      ], [])
    ], [])
    .groupBy(q => q.key);
  for (const group of resetDuplicatesGroupOnKey) {
    for (const question of group.values) {
      toggleQuestion(modules, question.moduleId, question.questionId, question.pageItemId);
    }
  }

  const duplicatesGroupOnKey: Grouping<ModulePageItem>[] = modules
    .reduce((am, cm) => [...am, ...cm.pageGroups
      .reduce((apg, cpg) => [...apg, ...cpg.pages
        .reduce((ap, cp) => [...ap, ...cp.pageItems.filter(pi => pi.questionId != null && !pi.excluded)
        ], []).map(obj => ({ ...obj, moduleId: cm.id, questionPriority: cm.questionPriority, moduleOrder: cm.order }))
      ], [])
    ], [])
    .groupBy(q => q.key).filter(q => q.values.length > 1);

  const cmp = (a: number, b: number) => {
    if (a > b) { return 1; }
    if (a < b) { return -1; }
    return 0;
  };

  for (const question of duplicatesGroupOnKey) {
    // sort on questionPrio first, then by companyId, if it's larger than one it may be considered their own.
    // Sort on moduleOrder last as fallback.
    const questions = question.values
      .sort(
        (a, b) =>
          cmp(a.questionPriority, b.questionPriority) ||
          cmp(b.companyId, a.companyId) ||
          (+b.hasRules) - (+a.hasRules) ||
          cmp(a.moduleOrder, b.moduleOrder));

    for (const duplicate of questions.slice(1)) {
      toggleQuestion(modules, duplicate.moduleId, duplicate.questionId, duplicate.pageItemId);
    }
  }
}

function initReminders(surveyWizardContext: SurveyWizardContext): ReminderEmail[] {
  if (surveyWizardContext.schedule.reminderEmails.length === 0) {
    return getReminderEmails(
      surveyWizardContext.schedule.startDate,
      surveyWizardContext.schedule.endDate,
      surveyWizardContext.communication.activationEmail.id);
  } else {
    const reminders = [...surveyWizardContext.schedule.reminderEmails];
    reminders.forEach(reminder => {
      reminder = { ...reminder, timeLabel: getTimeLabel(reminder.date) };
    });
    return reminders;
  }
}

export function getReminderEmails(
  startDate: Date,
  endDate: Date,
  activationEmailId: number) {
  if (endDate == null) { return []; }

  const _endDate = new Date(endDate.getFullYear(), endDate.getMonth(), (endDate.getDate()), 9, 0, 0);
  const reminderEmails = [];
  const daysDiff = getDaysDiff(startDate, _endDate);
  if (!activationEmailId || daysDiff < 4) {
    return reminderEmails;
  }
  let i = 0;
  let twoBusinessDaysAgo = _endDate;
  while (i < 2) {
    twoBusinessDaysAgo = getLastBusinessDay(twoBusinessDaysAgo);
    i++;
  }
  reminderEmails.push({ emailId: activationEmailId, date: twoBusinessDaysAgo, timeLabel: '09:00' });
  if (daysDiff > 7) {
    let fiveBusinessDaysAgo = _endDate;
    i = 0;
    while (i < 5) {
      fiveBusinessDaysAgo = getLastBusinessDay(fiveBusinessDaysAgo);
      i++;
    }
    reminderEmails.unshift({ emailId: activationEmailId, date: fiveBusinessDaysAgo, timeLabel: '09:00' });
  }
  return reminderEmails;
}
function getLastBusinessDay(d: Date) {
  let bd = d.getDay(); // get day from current date which be between 0-6 (0 is Sunday and 6 is Saturday)
  if (bd < 2) {
    if (bd === 0) {
      bd = 2;
    } else {
      bd = 3;
    }
  } else {
    bd = 1;
  }
  // variable bd will get the last business day by reducing the calculated lbd from today's date
  return new Date(d.getFullYear(), d.getMonth(), (d.getDate() - bd), 9, 0, 0);
}
export function getNextBusinessDay(d: Date) {
  const day = d.getDay();
  const add = 1 + (day === 5 && 2) + (day === 6 && 1);
  d.setDate(d.getDate() + add);
  return d;
}

export function getDaysDiff(d1: Date, d2: Date) {
  const diffc = d1.getTime() - d2.getTime();
  return Math.round(Math.abs(diffc / (1000 * 60 * 60 * 24)));
}

export function getSignedDaysDiff(d1: Date, d2: Date) {
  const diffc = d1.getTime() - d2.getTime();
  return Math.round(diffc / (1000 * 60 * 60 * 24));
}

export function getTimeLabel(date: Date): string {
  const hours = date.getHours().toString();
  const minutes = date.getMinutes().toString();
  return (hours.length === 1 ? '0' + hours : hours) + ':' + (minutes.length === 1 ? '0' + minutes : minutes);
}
function sortModules(modules: Module[]) {
  modules.sort((a, b) => {
    const aModuleOrder = +(a.meta?.find(ma => ma.metaId === 16)?.value ?? 9999999);
    const bModuleOrder = +(b.meta?.find(mb => mb.metaId === 16)?.value ?? 9999999);
    if (aModuleOrder && bModuleOrder) {
      if (aModuleOrder > bModuleOrder) {
        return 1;
      } else if (aModuleOrder < bModuleOrder) {
        return -1;
      }
    }
    return 0;
  });
}

function secondsDiff(d1, d2) {
  return Math.floor((d2 - d1) / 1000);
}
function minutesDiff(d1, d2) {
  const seconds = secondsDiff(d1, d2);
  return Math.floor(seconds / 60);
}
function isDirect(date: Date) {
  const _minutesDiff = minutesDiff(date.getTime(), todaysDate.getTime());
  return _minutesDiff < 30 && _minutesDiff > -30;
}
