import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CompanyContextActions from 'app/state/actions/company-context.actions';
import * as ReportActions from 'app/state/actions/report.actions';
import * as UserStateActions from 'app/state/actions/user-state.actions';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions) { }

  invalidateOnComanyChange = createEffect(() => this.actions$.pipe(
    ofType<CompanyContextActions.SetCompanyContext>(CompanyContextActions.Type.SET_COMPANY_CONTEXT),
    mergeMap(_ => {
      return [
        new ReportActions.Invalidate()
      ]
    })
  ));

  changeGroupSetHierarchy = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.GroupSelectorChangeGroup>(ReportActions.Type.GROUP_SELECTOR_CHANGE_GROUP),
    filter(action => !!action.payload.groupParameter),
    mergeMap(action => {
      return [
        new ReportActions.Invalidate(),
        new UserStateActions.UpdateParameter({ name: action.payload.groupParameter.key, value: action.payload.groupParameter.value.toString() })
      ]
    })
  ));

  setReport = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetActiveReport>(ReportActions.Type.SET_ACTIVE_REPORT),
    switchMap(action => {
      return [new ReportActions.SetActiveReportSuccess(action.payload)];
    })
  ));

  setFilterSettings = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetSettingsFilter>(ReportActions.Type.SET_SETTINGS_FILTER),
    switchMap(action => [new ReportActions.SetSettingsFilterSuccess(action.payload)])
  ));

  setFilterSorting = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetSortingFilter>(ReportActions.Type.SET_SORTING_FILTER),
    switchMap(action => [new ReportActions.SetSortingFilterSuccess(action.payload)])
  ));

  setFilterQuestion = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetQuestionFilter>(ReportActions.Type.SET_QUESTION_FILTER),
    switchMap(action => [new ReportActions.SetQuestionFilterSuccess(action.payload)])
  ));

  setFilterFreetextQuestion = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetFreetextQuestionFilter>(ReportActions.Type.SET_FREETEXT_QUESTION_FILTER),
    switchMap(action => [new ReportActions.SetFreetextQuestionFilterSuccess(action.payload)])
  ));

  setFilterRecommendationArea = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetAreaFilter>(ReportActions.Type.SET_AREA_FILTER),
    switchMap(action => [new ReportActions.SetAreaFilterSuccess(action.payload)])
  ));

  setIndexFilter = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetIndexFilter>(ReportActions.Type.SET_INDEX_FILTER),
    switchMap(action => [new ReportActions.SetIndexFilterSuccess(action.payload)])
  ));

  setStatusFilter = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetStatusFilter>(ReportActions.Type.SET_STATUS_FILTER),
    switchMap(action => [new ReportActions.SetStatusFilterSuccess(action.payload)])
  ));

  setFilterTonalityCategory = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetTonalityCategoryFilter>(ReportActions.Type.SET_TONALITY_CATEGORY_FILTER),
    switchMap(action => [new ReportActions.SetTonalityCategoryFilterSuccess(action.payload)])
  ));
  setFilterTonality = createEffect(() => this.actions$.pipe(
    ofType<ReportActions.SetTonalityFilter>(ReportActions.Type.SET_TONALITY_FILTER),
    switchMap(action => [new ReportActions.SetTonalityFilterSuccess(action.payload)])
  ));


}
