import { Answer, Content, FieldType, ModulePageGroup, Question, SurveyPage, SurveyPageGroup, SurveyUIState, SurveyUITheme } from 'app/shared/models';
import { LayoutType } from 'app/shared/survey-render/models';
import { SurveyNavigationBookmark } from 'app/shared/survey-render/models/survey-navigation.model';
import { Actions, Type } from 'app/state/actions/survey-ui.actions';
import { cloneDeep } from 'lodash-es';

const initialState: SurveyUIState = {
  sessionId: 0,
  languageCode: '',
  languageId: 1,
  hierarchyId: null,
  groupId: null,
  languageDir: 'ltr',
  loaded: false,
  loading: false,
  chapterPagesTotalCount: 0,
  pageCurrent: 0,
  chapterTotalCount: 0,
  chapterCurrent: 0,
  progressChapterPagesTotalCount: 0,
  progressChaptersCount: 0,
  progressPageCurrent: 0,
  progressChapterCurrent: -1,
  page: <SurveyPage>{},
  pageGroups: [],
  answers: [],
  showFastForwardButton: false,
  fastForwardChapter: 0,
  fastForwardPage: 0,
  previousDisabled: false,
  nextDisabled: false,
  previewMode: false,
  animationDirectionNext: true,
  noSurvey: { noSurvey: false, expiredSurvey: false },
  rules: [],
  ruleDependencies: [],
  showSkipModal: false,
  singlePageQuestionSkipped: false,
  isSubmitted: false,
  isSubmitting: false,
  submitFailed: false,
  moduleId: 0,
  modulePageGroups: [],
  theme: null
};

const resetState = JSON.stringify(initialState);

export function reducer(state: SurveyUIState = initialState, action: Actions): SurveyUIState {

  switch (action.type) {
    case Type.SAVE_ANSWERS_DATABASE: {
      return { ...state };
    }
    case Type.SAVE_ANSWERS_DATABASE_SUCCESS: {
      return { ...state };
    }
    case Type.SAVE_ANSWERS_DATABASE_FAILURE: {
      return { ...state };
    }
    case Type.LOAD_SURVEY_SURVEY_EXPIRED: {
      return {
        ...state,
        noSurvey: {
          noSurvey: false,
          expiredSurvey: true
        },
        loaded: true,
        loading: false,
        theme: SurveyUITheme.Default
      };
    }
    case Type.LOAD_SURVEY_CONTENT_SUCCESS: {
      const survey = action.payload;
      if (!survey) {
        return {
          ...state,
          loaded: true,
          loading: false,
          noSurvey: {
            noSurvey: true,
            expiredSurvey: false
          },
          theme: SurveyUITheme.Default
        }
      }
      const items = survey.surveyModule.pageGroups;
      const theme = survey.theme
        ? SurveyUITheme[survey.theme]
        : SurveyUITheme.Default;


      const newSurvey: SurveyUIState = {
        ...state,
        sessionId: survey.sessionId,
        languageCode: survey.languageCode,
        languageId: survey.languageId,
        languageDir: survey.isRightToLeft ? 'rtl' : 'ltr',
        hierarchyId: survey.hierarchyId,
        groupId: survey.groupId,
        previewMode: false,
        pageGroups: items,
        chapterTotalCount: items.length,
        progressChaptersCount: items.filter(visibleAndIncluded).length,
        chapterPagesTotalCount: items[state.chapterCurrent].pages.length,
        progressChapterPagesTotalCount: items[state.chapterCurrent].pages.filter(visibleAndIncluded).length,
        loaded: true,
        loading: false,
        page: items[state.chapterCurrent].pages[state.pageCurrent],
        nextDisabled: state.nextDisabled,
        rules: survey.rules,
        ruleDependencies: survey.ruleDependencies,
        noSurvey: { noSurvey: false, expiredSurvey: false },
        theme: theme
      };
      return newSurvey;
    }
    case Type.LOAD_SURVEY_STATE: {
      return action.payload;
    }

    case Type.LOAD_ANSWERS_DATABASE_SUCCESS: {
      return {
        ...state,
        answers: action.payload
      };
    }

    case Type.SUBMIT_SURVEY: {
      return {
        ...state,
        isSubmitted: false,
        isSubmitting: true,
        submitFailed: false
      };
    }

    case Type.SUBMIT_SURVEY_FAILURE: {
      return {
        ...state,
        isSubmitted: false,
        isSubmitting: false,
        submitFailed: true
      };
    }

    case Type.SUBMIT_SURVEY_SUCCESS: {
      return {
        ...state,
        pageCurrent: state.pageCurrent + 1,
        page: state.pageGroups[state.chapterCurrent].pages.find(x => x.key === LayoutType.DonePage),
        nextDisabled: true,
        previousDisabled: true,
        isSubmitted: true,
        isSubmitting: false,
        submitFailed: false
      };
    }

    case Type.SET_FAST_FORWARD_PAGE: {

      // Default fast forward is submit page
      const fastForwardChapter = [...state.pageGroups].findIndex(pg => pg.pages.find(p => p.key === LayoutType.SubmitPage));
      const fastForwardPage = [...state.pageGroups][fastForwardChapter]?.pages.findIndex(p => p.key === LayoutType.SubmitPage);
      const surveyState: SurveyUIState = {
        ...state,
        fastForwardChapter: fastForwardChapter,
        fastForwardPage: fastForwardPage
      };

      for (let i = 0; i < state.pageGroups.length; i++) {
        if (state.pageGroups[i].visible === false) { continue; }
        for (let j = 0; j < state.pageGroups[i].pages.length; j++) {
          if (state.pageGroups[i].pages[j].visible === false
            || state.pageGroups[i].pages[j].key === LayoutType.SkipPage) { continue; }
          for (const item of state.pageGroups[i].pages[j].pageItems.filter(pi => pi.question)) {
            if (!state.answers.find(a => a.questionId === item.question.questionId)) {
              if (item.question.visible !== false) {
                surveyState.fastForwardChapter = i;
                surveyState.fastForwardPage = j;
                return surveyState;
              }
            }
          }
        }
      }
      return surveyState;
    }

    case Type.GO_TO_PAGE_SUCCESS: {
      const bookmark: SurveyNavigationBookmark = { ...action.payload };

      let nextPage: number;
      let nextChapter: number;

      const fastForwardChapter = state.fastForwardChapter;
      const fastForwardPage = state.fastForwardPage;

      if (!bookmark.chapterIndex) {
        bookmark.chapterIndex = state.chapterCurrent;
      }

      // Trying to navigate to part of survey, past answered questions
      if (bookmark.chapterIndex > fastForwardChapter
        || (bookmark.chapterIndex === fastForwardChapter && bookmark.pageIndex > fastForwardPage)) { return state; }

      const numberOfPagesInBookmarkChapter =
        state.pageGroups[bookmark.chapterIndex]
          ? state.pageGroups[bookmark.chapterIndex].pages
            .length
          : 0;

      // Is next page out of bounds for this chapter?
      if (bookmark.pageIndex >= numberOfPagesInBookmarkChapter) {

        nextChapter = bookmark.chapterIndex + 1;
        nextPage = bookmark.pageIndex - numberOfPagesInBookmarkChapter;

        // Are there visible pages in next chapter?
        for (let i = nextChapter; i < state.pageGroups.length; i++) {
          if (!state.pageGroups[i].visible) {
            continue;
          }
          if (state.pageGroups[i].pages.filter(p => p.visible !== false).length === 0) {
            continue;
          }
          nextChapter = i;
          nextPage = bookmark.pageIndex - numberOfPagesInBookmarkChapter;

          for (let j = nextPage; j < state.pageGroups[nextChapter].pages.length - 1; j--) {
            if (state.pageGroups[nextChapter].pages[j].visible) {
              nextPage = j;
              break;
            }
          }
          break;
        }
      } else if (bookmark.pageIndex < 0) {
        nextChapter = bookmark.chapterIndex - 1;
        nextPage = state.pageGroups[nextChapter]?.pages.length - 1;

        // Are there visible pages in next chapter?
        for (let i = nextChapter; i > 0; i--) {
          if (!state.pageGroups[i].visible) {
            continue;
          }
          if (state.pageGroups[i].pages.filter(p => p.visible !== false).length === 0) {
            continue;
          }
          nextChapter = i;
          nextPage = state.pageGroups[nextChapter]?.pages.length - 1;
          for (let j = nextPage; j >= 0; j--) {
            if (state.pageGroups[nextChapter].pages[j].visible) {
              nextPage = j;
              break;
            }
          }

          break;
        }

      } else {
        nextChapter = bookmark.chapterIndex;
        nextPage = bookmark.pageIndex;

        if (bookmark.pageIndex > state.pageCurrent) {
          // moving forward
          outer:
          for (let j = bookmark.chapterIndex; j < state.pageGroups.length; j++) {
            nextChapter = j;
            for (let i = bookmark.pageIndex; i < numberOfPagesInBookmarkChapter; i++) {
              if (state.pageGroups[j].pages[i].visible) {
                nextPage = i;
                break outer;
              }
            }
            bookmark.pageIndex = 0;

          }
        } else {
          // moving backwards
          for (let i = bookmark.pageIndex; i >= 0; i--) {
            if (state.pageGroups[bookmark.chapterIndex].pages[i].visible) {
              nextPage = i;
              break;
            }
          }
        }
      }

      const progressChapter = chapterProgressAtIndex(state, nextChapter);
      const progressPage = nextPage;

      const pageItems = state.pageGroups[nextChapter]?.pages[nextPage]?.pageItems;
      const contentList = pageItems?.filter(c => c.content && c.question == null && visible(c.content)).map(c => c.content);
      const questionList = pageItems?.filter(c => c.question && c.content == null && visible(c.question)).map(c => c.question);
      const ids = pageItems?.filter(c => c.question && c.content == null).map(x => x.question.questionId);
      const isAnswered = state.answers.filter(o => ids.some(x => x === o.questionId)).length > 0 ? true : false;

      const surveyState = {
        ...state,
        chapterCurrent: nextChapter,
        pageCurrent: nextPage,
        chapterPagesTotalCount: state.pageGroups[nextChapter]?.pages?.length ?? 0,
        progressChapterPagesTotalCount: state.pageGroups[nextChapter]?.pages?.filter(visibleAndIncluded).length ?? 0,
        progressChapterCurrent: progressChapter,
        progressPageCurrent: progressPage,
        page: state.pageGroups[nextChapter]?.pages[nextPage],
        nextDisabled: (questionList?.length > 0 && !isAnswered)
          || (state.pageGroups[nextChapter]?.pages[nextPage].key === LayoutType.DonePage
            || (state.pageGroups[nextChapter]?.pages[nextPage].key === LayoutType.SubmitPage)),
        showFastForwardButton: !(questionList?.length > 0 && !isAnswered) && contentList?.length === 0,
        previousDisabled: state.pageGroups[nextChapter]?.pages[nextPage].key === LayoutType.DonePage,
        animationDirectionNext: true,
        singlePageQuestionSkipped: state.pageGroups[nextChapter]?.pages[nextPage].pageItems[0].question ?
          isSkipButtonSelectedForQuestion(state,
            state.pageGroups[nextChapter]?.pages[nextPage].pageItems[0].question.questionId) : false

      };

      return surveyState;
    }

    case Type.REGISTER_ANSWER: {
      const tempAnswers = JSON.parse(JSON.stringify(state.answers)) as Answer[];
      const answerPayload = action.payload;

      tempAnswers.removeEvery(x => x.questionId === answerPayload.questionId);
      if (answerPayload.dataType === FieldType.MultipleChoice && !answerPayload.skipValue) {
        const multipleOptions = answerPayload.value.split(',');
        for (const option of multipleOptions) {
          tempAnswers.push({ ...answerPayload, value: option, optionValue: +option, hierarchyId: state.hierarchyId, groupId: state.groupId });
        }
      } else {
        tempAnswers.push({ ...answerPayload, hierarchyId: state.hierarchyId, groupId: state.groupId });
      }

      let newState: SurveyUIState = null;
      if (answerPayload.skipValue) {
        newState = cloneDeep(state);
        newState.pageGroups[state.chapterCurrent].pages[state.pageCurrent].pageItems[0].question.questionFields[0].answer = null;
      }

      return {
        ...state,
        answers: tempAnswers,
        nextDisabled: false,
        showSkipModal: false
      };
    }
    case Type.REMOVE_ANSWER: {
      const tempAnswers = JSON.parse(JSON.stringify(state.answers)) as Answer[];
      const answerPayload = action.payload;
      tempAnswers.removeEvery(a => a.questionId === answerPayload.questionId);
      return {
        ...state,
        answers: tempAnswers,
        nextDisabled: false,
        showSkipModal: false
      };
    }
    case Type.LOAD_PREVIEW: {
      return {
        ...state,
        loading: true,
        loaded: false,
        moduleId: action.payload.moduleId,
        modulePageGroups: action.payload.pageGroups as ModulePageGroup[]
      };
    }
    case Type.LOAD_PREVIEW_SUCCESS: {
      const items = action.payload.pageGroups;
      return {
        ...state,
        languageCode: action.payload.languageCode,
        sessionId: null,
        previewMode: true,
        pageGroups: items,
        chapterTotalCount: items.length,
        progressChaptersCount: items.filter(visibleAndIncluded).length,
        chapterPagesTotalCount: items[state.chapterCurrent].pages.length,
        progressChapterPagesTotalCount: items[state.chapterCurrent].pages.filter(visibleAndIncluded).length,
        loaded: true,
        loading: false,
        nextDisabled: false,
        page: items[state.chapterCurrent].pages[state.pageCurrent],
        rules: action.payload.rules,
        ruleDependencies: action.payload.ruleDependencies
      }
    }

    case Type.SET_QUESTION_VISIBILITY: {
      const _pageGroups = cloneDeep(state.pageGroups);
      let pages: SurveyPage[] = [];
      const pageGroups: SurveyPageGroup[] = [];
      for (const pageGroup of _pageGroups) {
        pages = [];
        for (const page of pageGroup.pages) {
          for (const pageItem of page.pageItems
            .filter(pi => pi.question != null)) {
            for (const question of action.payload) {

              if (pageItem.question.questionId === question.id) {
                pageItem.question = { ...pageItem.question, visible: question.visible };
              }
            }
          }
          pages.push({
            ...page,
            visible: page.pageItems.some(pi => (pi.content && visible(pi.content)) || (pi.question && visible(pi.question)))
          });
        }
        pageGroups.push({
          ...pageGroup,
          pages: [...pages], visible: pages.some(p => p.key !== LayoutType.ChapterPage && visible(p))
        });
      }
      return {
        ...state,
        pageGroups: pageGroups,
        progressChaptersCount: pageGroups.filter(visibleAndIncluded).length,
        progressChapterPagesTotalCount: pageGroups[state.chapterCurrent].pages.filter(visibleAndIncluded).length
      };
    }
    case Type.SET_CONTENT_VISIBILITY: {
      const _pageGroups = cloneDeep(state.pageGroups);
      let pages: SurveyPage[] = [];
      const pageGroups: SurveyPageGroup[] = [];
      for (const pageGroup of _pageGroups) {
        pages = [];
        for (const page of pageGroup.pages) {
          for (const pageItem of page.pageItems
            .filter(pi => pi.content != null && pi.content.id === action.payload.contentId)) {
            pageItem.content = { ...pageItem.content, visible: action.payload.visible };
          }
          pages.push({
            ...page,
            visible: page.pageItems.some(pi => (pi.content && visible(pi.content)) || (pi.question && visible(pi.question)))
          });
        }
        pageGroups.push({
          ...pageGroup,
          pages: [...pages], visible: pageGroup.pages.some(p => p.key !== LayoutType.ChapterPage && visible(p))
        });
      }
      return {
        ...state,
        pageGroups: pageGroups,
        progressChaptersCount: pageGroups.filter(visibleAndIncluded).length,
        progressChapterPagesTotalCount: pageGroups[state.chapterCurrent].pages.filter(visibleAndIncluded).length
      };
    }

    case Type.SET_CHAPTER_VISIBILITY: {
      const pageGroups = [...state.pageGroups];
      const pageGroup = pageGroups.find(pg => pg.key === action.payload.pageGroupKey);
      if (pageGroup) {
        pageGroup.visible = action.payload.visible;
      }
      return {
        ...state,
        pageGroups: pageGroups,
        progressChaptersCount: pageGroups.filter(visibleAndIncluded).length,
        progressChapterPagesTotalCount: pageGroups[state.chapterCurrent].pages.filter(visibleAndIncluded).length
      };
    }

    case Type.CLEAR_STATE: {

      // Preserve theme, to prevent flickering in ui
      const theme = state.theme;

      const _resetState = JSON.parse(resetState) as SurveyUIState;
      _resetState.answers = [...state.answers];
      _resetState.theme = theme;
      return _resetState;
    }

    case Type.LOAD_CHAPTER_SUMMARY: {
      const chapterOrder = action.payload as number;
      let chapterSummaryPage: SurveyPage =
      {
        order: 0,
        pageItems: [],
        key: LayoutType.ChapterSummaryPage,
        includedInProgress: false,
        showPageProgress: false,
        showNavigation: false,
        showAbout: false,
        showChapterProgress: true,
        visible: true
      };

      chapterSummaryPage = {
        ...chapterSummaryPage,
        ...state.pageGroups[chapterOrder].pages
          .find(x => x.key === LayoutType.ChapterPage)
      };


      chapterSummaryPage.key = LayoutType.ChapterSummaryPage;
      return {
        ...state,
        page: chapterSummaryPage,
        pageCurrent: -1,
        chapterCurrent: chapterOrder,
        progressChapterCurrent: chapterProgressAtIndex(state, chapterOrder),
        showFastForwardButton: true,
        animationDirectionNext: false
      };
    }

    case Type.SKIP_PAGE: {
      return {
        ...state,
        showSkipModal: true
      };
    }

    case Type.CANCEL_SKIP: {
      return {
        ...state,
        showSkipModal: false
      };
    }

    case Type.PREVIEW_THEME_SELECTED: {
      return {
        ...state,
        theme: action.payload
      }
    }

    default: {
      return state;
    }
  }
}

function chapterProgressAtIndex(state: SurveyUIState, index: number) {
  const notVisibleCount = state.pageGroups.filter((pg, idx) => !visibleAndIncluded(pg) && idx <= index).length;
  return index - notVisibleCount;
}


function isSkipButtonSelectedForQuestion(state: SurveyUIState, questionId: number): boolean {
  if (state.answers && state.answers.length > 0
    && state.answers.filter(a => a.questionId === questionId && a.skipValue).length > 0) {
    return true;
  }
  return false;
}

function visibleAndIncluded(x: SurveyPage | SurveyPageGroup) {
  return x.includedInProgress === true && visible(x);
}

function visible(x: SurveyPage | SurveyPageGroup | Question | Content) {
  return x.visible === undefined || x.visible;
}
