import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { structure } from 'app/admin-tpl/company-overview/reports/templates/default';
import { indexReportPage } from 'app/admin-tpl/company-overview/reports/templates/default/index-report-page';
import { ConfigurationService } from 'app/shared';
import {
  ConditionDto,
  ContentItem,
  ContentItemDetailed, DataConditionDto, DataConditionGroup,
  IndexHeaderData,
  UserState,
  ReportDefinitionDto, ReportDefinitionSimple,
  ReportPage
} from 'app/shared/models';
import { ReportPageDto, ReportPageItemDto, ReportPageItemType, ReportStructureDto } from 'app/shared/models/menu/report-page.model';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportPageService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  get(companyId: number, key: string): Observable<ReportPage> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      })
    };

    return this._http
      .get<ReportPage>(`${this._config.apiUrl}/api/report-page/${companyId}/${key}`, httpOptions);
  }

  createReportDefinition(model: ReportDefinitionDto): Observable<ReportDefinitionDto> {
    return this._http.post<ReportDefinitionDto>(`${this._config.apiUrl}/api/reportdefinitions`, model);

  }

  updateReportDefinition(model: ReportDefinitionDto): Observable<ReportDefinitionDto> {
    return this._http.put<ReportDefinitionDto>(`${this._config.apiUrl}/api/reportdefinitions`, model);
  }

  getReportDefinitions(companyId: number): Observable<ReportDefinitionSimple[]> {
    return this._http.get<ReportDefinitionSimple[]>(`${this._config.apiUrl}/api/reportdefinitions/${companyId}/list`);
  }
  getCompanyReportDefinitions(): Observable<ReportDefinitionSimple[]> {
    return this._http.get<ReportDefinitionSimple[]>(`${this._config.apiUrl}/api/companyreportdefinitions`);
  }

  exportReportDefinition(reportId: number, companyId: number, params: Record<string, string>) {
    return this._http.get(
      `${this._config.apiUrl}/api/reportdefinitions/${companyId}/render/${reportId}`,
      {
        observe: 'response',
        responseType: 'blob',
        params: new HttpParams({ fromObject: params })
      });
  }

  getDefaultReportDefinitions(): Observable<ReportDefinitionSimple[]> {
    return this._http.get<ReportDefinitionSimple[]>(`${this._config.apiUrl}/api/reportdefinitions`);
  }

  getReportDefinitionDetails(reportId: number): Observable<ReportDefinitionDto> {
    return this._http.get<ReportDefinitionDto>(`${this._config.apiUrl}/api/reportdefinitions/${reportId}/details`);
  }

  getReportPageNames(companyId: number, key: string): Observable<ContentItemDetailed[]> {
    return this._http.get<ContentItemDetailed[]>(`${this._config.apiUrl}/api/report-page/${companyId}/page/${key}/names`);
  }

  createReportPage(companyId: number, parentKey: string, key: string, items: ContentItem[]) {
    const pageDto = indexReportPage(key, items);
    return this._http.post(`${this._config.apiUrl}/api/report-page/${companyId}/page/${parentKey}`, pageDto);
  }

  getReportPageItem(companyId: number, pageKey: string, itemType: ReportPageItemType) {
    return this._http
      .get<ReportPageItemDto>(`${this._config.apiUrl}/api/report-page/${companyId}/page/${pageKey}/item-type/${itemType}`)
      .pipe(
        catchError(_ => {
          const resultChildren: ReportPageDto[] = (structure as any).find(s => s.key === 'results')?.children;

          const pageItems =
            resultChildren
              ?.find(c => c.key === pageKey)
              ?.reportPageItems;

          const pageItem = pageItems?.find(oi => oi.reportPageItemType === itemType);
          if (pageItem == null) {
            return throwError(() => new Error(`Could not find reportPageItem of type ${itemType} in ${pageKey}`));
          } else {
            return of(pageItem);
          }
        })
      );
  }

  createReportPageItem(companyId: number, pageKey: string, pageItem: ReportPageItemDto) {
    return this._http.post(`${this._config.apiUrl}/api/report-page/${companyId}/page/${pageKey}/item`, pageItem);
  }

  post(dto: ReportStructureDto) {
    return this._http.post(`${this._config.apiUrl}/api/report-page`, dto);
  }

  removeReportPage(companyId: number, key: string) {
    return this._http.delete(`${this._config.apiUrl}/api/report-page/${companyId}/page/${key}`);
  }

  getStructure(companyId: number): Observable<ReportStructureDto> {
    return this._http.get<ReportStructureDto>(`${this._config.apiUrl}/api/report-page/${companyId}/structure`);
  }

  getIndexHeaderData(companyId: number, key: string, userState: UserState): Observable<IndexHeaderData> {
    return this._http.post<IndexHeaderData>(`${this._config.apiUrl}/api/report-page/${companyId}/${key}/indexheader`, userState);
  }

  cleanPage(page: ReportPageDto) {
    if (page.meta === null) { delete page['meta']; }
    if (page.name === null) { delete page['name']; }
    if (page.parameter === null) { delete page['parameter']; }
    if (page.groupName === null) { delete page['groupName']; }
    if (page.condition === null) { delete page['condition']; } else { this.cleanCondition(page.condition); }

    if (page.dataConditions == null || page.dataConditions.length === 0) {
      delete page['dataConditions'];
    } else if (page.dataConditions && page.dataConditions.length > 0) {
      page.dataConditions.forEach(dc => this.cleanDataCondition(dc));
    }

    if (page.dataSourceNames && page.dataSourceNames.length === 0) {
      delete page['dataSourceNames'];
    }

    if (page.children && page.children.length === 0) {
      delete page['children'];
    } else if (page.children && page.children.length > 0) {
      page.children.forEach(c => this.cleanPage(c));
    }

    if (page.reportPageItems && page.reportPageItems.length === 0) {
      delete page['reportPageItems'];
    } else if (page.reportPageItems && page.reportPageItems.length > 0) {
      page.reportPageItems.forEach(i => this.cleanReportPageItem(i));
    }

  }

  private cleanReportPageItem(item: ReportPageItemDto) {
    if (item.name === null) { delete item['name']; }
    if (item.key === null) { delete item['key']; }
    if (item.condition === null) {
      delete item['condition'];
    } else {
      this.cleanCondition(item.condition);
    }
  }

  private cleanCondition(condition: ConditionDto) {
    if (!condition) { return; }
    if (condition.name === null) { delete condition['name']; }
    if (condition.logic === null) { delete condition['logic']; }
    if (condition.leftSourceId === null) { delete condition['leftSourceId']; }
    if (condition.leftField === null) { delete condition['leftField']; }
    if (condition.leftDataSourceName === null) { delete condition['leftDataSourceName']; }
    if (condition.leftValue === null) { delete condition['leftValue']; }
    if (condition.rightSourceId === null) { delete condition['rightSourceId']; }
    if (condition.rightField === null) { delete condition['rightField']; }
    if (condition.rightDataSourceName === null) { delete condition['rightDataSourceName']; }
    if (condition.rightValue === null) { delete condition['rightValue']; }
    if (condition.conditions == null || condition.conditions.length === 0) {
      delete condition['conditions'];
    } else {
      condition['conditions'].forEach(cc => this.cleanCondition(cc));
    }
  }

  private cleanDataCondition(dc: DataConditionDto) {
    if (dc.groups && dc.groups.length > 0) {
      dc.groups.forEach(cg => this.cleanDataConditionGroup(cg));
      if (dc.condition === null) { delete dc['condition']; }
    }
  }

  private cleanDataConditionGroup(dcg: DataConditionGroup) {
    if (dcg.groups && dcg.groups.length === 0) {
      delete dcg['groups'];
    } else if (dcg.groups && dcg.groups.length > 0) {
      dcg.groups.forEach(cg => this.cleanDataConditionGroup(cg));
    }
    if (dcg.name === null) { delete dcg['name']; }
    if (dcg.condition === null) { delete dcg['condition']; }
    if (dcg.groupField === null) { delete dcg['groupField']; }
    if (dcg.labels === null) { delete dcg['labels']; }
    if (dcg.dataFields === null) { delete dcg['dataFields']; }
    if (dcg.orderBy === null) { delete dcg['orderBy']; }
  }

}

