import { BooleanInput, coerceBooleanProperty, coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
export const symbolPath = '/assets/ui-kit/symbol-defs.svg';

@Component({
  selector: 'uikit-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIKitIcon {
  symbolPath = symbolPath;
  _icon: string;
  @Input()
  public set icon(key: string) {
    this._icon = (key === 'heart' && this.isHolidaySeason()) 
      ? 'christmas-tree-2-menu' 
      : key;
  }

  public get icon(): string {
    return this._icon;
  }

  private isHolidaySeason(): boolean {
    const d = new Date();
    return d.getMonth() === 12;
  }


  /* Size in px */
  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  _size: number;
  @Input()
  set size(value: NumberInput) {
    this._size = coerceNumberProperty(value);
  }

  @HostBinding('class.block') private _block = false;

  @Input()
  get block(): boolean {
    return this._block;
  }
  set block(value: BooleanInput) {
    this._block = coerceBooleanProperty(value)
  }
}
