import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Interface } from 'app/helpers/interface';
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    constructor(private _http: HttpClient, private _config: ConfigurationService) {
    }

    getReport(reportName: string, role: string): Observable<any> {
      return this._http.get<any>(`${this._config.apiUrl}/api/analytics/${reportName}/${role}`);
    }
}

export class MockAnalyticsService implements Interface<AnalyticsService> {
    getReport(reportName: string): Observable<any> {
        return observableOf({});
    }
}
