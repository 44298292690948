import { Condition, ModuleMeta } from '.';
import { NoResultReason } from './result-pages';

export const SnapshotVisibility = {
  hidden: 'Hidden',
  test: 'Test',
  preview: 'Preview',
  earlyAccess: 'EarlyAccess',
  visible: 'Visible',
  lateAccess: 'LateAccess',
};
export const SnapshotType = {
  standard: 'Standard',
  benchmark: 'Benchmark',
  custom: 'Custom',
  migrated: 'Migrated',
  total360: "Total360",
  employee360: 'Employee360',
  manager360: 'Manager360',
  colleague360: 'Colleague360',
  myself: 'Myself'
}
export const SnapshotResponseSource = {
  groupUsers: 'GroupUsers',
  groupResponses: 'GroupResponses'
}

export interface SnapshotTemplate
{
  key: string;
  name: string;
  template: CreateSnapshot
}
export const SnapshotTemplates : SnapshotTemplate[] = [
  {
    key: 'multipleSurveys',
    name: 'Default (combination of surveys)',
    template: {
      name: '',
      type: SnapshotType.custom,
      responseSource: SnapshotResponseSource.groupResponses,
      companyId: null,
      anonymityId: null,
      snapshotDate: null,
      surveyIds: [],
      visibility: SnapshotVisibility.preview,
      meta: null,
      settings: null,
      condition: null
    }
  },
  {
    key: 'multiple360Surveys',
    name: 'Combination of 360 surveys',
    template: {
      name: '',
      type: SnapshotType.total360,
      responseSource: SnapshotResponseSource.groupResponses,
      companyId: null,
      anonymityId: null,
      snapshotDate: null,
      surveyIds: [],
      visibility: SnapshotVisibility.preview,
      condition: {
        operator: 'Neq',
        logic: null,
        conditions: null,
        operand1: {
          source: 'UserRelation',
          value: 'Myself',
          field: '{group.manager.id}'
        },
        operand2: {
          source: 'Const',
          value: '1'
        }
      },
      children: [
        {
          name: 'Manager',
          type: SnapshotType.manager360,
          responseSource: SnapshotResponseSource.groupResponses,
          companyId: null,
          visibility: SnapshotVisibility.preview,
          condition: {
            operator: 'Eq',
            logic: null,
            conditions: null,
            operand1: {
              source: 'UserRelation',
              value: 'Manager360',
              field: '{group.manager.id}'
            },
            operand2: {
              source: 'Const',
              value: '1'
            }
          }
        },
        {
          name: 'Employee',
          type: SnapshotType.employee360,
          responseSource: SnapshotResponseSource.groupResponses,
          companyId: null,
          visibility: SnapshotVisibility.preview,
          condition: {
            operator: 'Eq',
            logic: null,
            conditions: null,
            operand1: {
              source: 'UserRelation',
              value: 'Employee360',
              field: '{group.manager.id}'
            },
            operand2: {
              source: 'Const',
              value: '1'
            }
          }
        },
        {
          name: 'Colleague',
          type: SnapshotType.colleague360,
          responseSource: SnapshotResponseSource.groupResponses,
          companyId: null,
          visibility: SnapshotVisibility.preview,
          condition: {
            operator: 'Eq',
            logic: null,
            conditions: null,
            operand1: {
              source: 'UserRelation',
              value: 'Colleague360',
              field: '{group.manager.id}'
            },
            operand2: {
              source: 'Const',
              value: '1'
            }
          }
        },
        {
          name: 'Myself',
          type: SnapshotType.myself,
          responseSource: SnapshotResponseSource.groupResponses,
          companyId: null,
          visibility: SnapshotVisibility.preview,
          condition: {
            operator: 'Eq',
            logic: null,
            conditions: null,
            operand1: {
              source: 'UserRelation',
              value: 'Myself',
              field: '{group.manager.id}'
            },
            operand2: {
              source: 'Const',
              value: '1'
            }
          }
        }
      ]
    }
  }
]


export interface Snapshot {
  id: number;
  name: string;
  label: string;
  snapshotDate?: Date;
  visibility?: string;
  visibilityTranslationKey?: string;
  meta?: ModuleMeta[];
  surveyIds: number[];
  responseSource?: string;
}

export interface GroupResult {
  id: number;
  groupName: string;
  groupId: string;
  filterId?: number;
  filterName: string;
  snapshotId: number;
  snapshotName: string;
  snapshotLabel?: string;
  label?: string;
  order: number;
  isBenchmark: boolean;
  noResultReason?: NoResultReason;
}

export interface EditSnapshot {
  id: number;
  name: string;
  snapshotDate: Date;
  settings: string;
  anonymityId: number;
  visibility: string;
  type: string;
  condition: Condition;
  surveyIds?: number[];
  responseSource?: string;
}

export interface CreateSnapshot {
  companyId: number;
  name: string;
  snapshotDate?: Date;
  anonymityId?: number;
  type: string;
  visibility: string;
  visibilityTranslationKey?: string;
  surveyIds?: number[];
  condition?: Condition;
  meta?: ModuleMeta[];
  children?: CreateSnapshot[];
  settings?: string;
  responseSource: string;

}
