import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyProductDefinition, EditCompanyProductDefinition, ProductDefinition, ProductDefinitionJson, ProductIndex, UpdateCompanyProductDefinition } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyProductDefinitionService {
  public static GetSurveyImageUrl(imageName: string) {
    return '/assets/images/survey/' + imageName;
  }
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }
  getByCompany(companyId: number, productType?: string, isActive?: boolean): Observable<CompanyProductDefinition[]> {
    const url = `${this._config.apiUrl}/api/companyproductdefinitions/byCompany`;
    const params = {
      companyId: companyId.toString(),
      productType: productType,
      isActive: isActive?.toString() || null
    };
    const response = this._http
      .get<CompanyProductDefinition[]>(url, {
        params: params
      }).pipe(
        shareReplay(1),
        map(res =>
          res.map((cpd): CompanyProductDefinition => ({
            ...cpd,
            definitionObject: JSON.parse(cpd.definition) as ProductDefinitionJson
          }))
        )
      );
    return response;
  }

  get(id: number): Observable<UpdateCompanyProductDefinition> {
    return this._http.get<UpdateCompanyProductDefinition>(`${this._config.apiUrl}/api/companyproductdefinitions/${id}`);
  }
  getTemplate(productId: number): Observable<UpdateCompanyProductDefinition> {
    return this._http.get<UpdateCompanyProductDefinition>(`${this._config.apiUrl}/api/companyproductdefinitions/product/${productId}`);
  }
  create(model: EditCompanyProductDefinition): Observable<ProductDefinition> {
    return this._http.post<ProductDefinition>(`${this._config.apiUrl}/api/companyproductdefinitions`, model);
  }
  update(id: number, model: EditCompanyProductDefinition): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/companyproductdefinitions/${id}`, model);
  }
  delete(id: number): Observable<void> {
    return this._http.delete<void>(`${this._config.apiUrl}/api/companyproductdefinitions/${id}`);
  }
  getIndexInfo(definitionId: number): Observable<ProductIndex[]> {
    return this._http
      .get<ProductIndex[]>(`${this._config.apiUrl}/api/companyproductdefinitions/${definitionId}/indexinfo`);
  }
  getIndexInfoVerbose(definitionId: number): Observable<ProductIndex[]> {
    return this._http
      .get<ProductIndex[]>(`${this._config.apiUrl}/api/companyproductdefinitions/${definitionId}/indexinfo/verbose`).pipe(shareReplay(1));
  }
  getProductViewByCompany(companyId: number, productType?: string, isActive?: boolean): Observable<CompanyProductDefinition[]> {
    const url = `${this._config.apiUrl}/api/companyproductdefinitions/view/byCompany`;
    const params = {
      companyId: companyId.toString(),
      productType: productType,
      isActive: isActive?.toString() || null
    };
    return this._http
      .get<CompanyProductDefinition[]>(url, {
        params: params
      }).pipe(
        map(res =>
          res.map((cpd): CompanyProductDefinition => ({
            ...cpd,
            definitionObject: JSON.parse(cpd.definition) as ProductDefinitionJson
          }))
        ),
        shareReplay(1)
      );
  }
}
