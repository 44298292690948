import { Action } from '@ngrx/store';
import { MenuItem, NavtreeItem, UserState } from 'app/shared/models';

export enum Type {
  LOAD_NAVTREE = '[NAVTREE] LOAD_NAVTREE',
  LOAD_NAVTREE_SUCCESS = '[NAVTREE] LOAD_NAVTREE_SUCCESS',
  LOAD_NAVTREE_CANCELED = '[NAVTREE] LOAD_NAVTREE_CANCELED',
  UPDATE_VISIBILITY = '[NAVTREE] UPDATE_VISIBILITY',
  UPDATE_PARAMETER_MENU_VISIBILITY = 'UPDATE_PARAMETER_MENU_VISIBILITY'
}

export class LoadNavtree implements Action {
  readonly type = Type.LOAD_NAVTREE;
}
export class LoadNavtreeCanceled implements Action {
  readonly type = Type.LOAD_NAVTREE_CANCELED;
}
export class LoadNavtreeSuccess implements Action {

  readonly type = Type.LOAD_NAVTREE_SUCCESS;
  constructor(public payload: NavtreeItem[]) { }
}

export class UpdateVisibility implements Action {

  readonly type = Type.UPDATE_VISIBILITY;
  constructor(public params: UserState, public items: MenuItem[]) { }
}

export class UpdateParameterMenuVisibility implements Action {

  readonly type = Type.UPDATE_PARAMETER_MENU_VISIBILITY;
  constructor(public params: UserState) { }
}


export type Actions = LoadNavtree | LoadNavtreeSuccess | LoadNavtreeCanceled
  | UpdateVisibility | UpdateParameterMenuVisibility;
