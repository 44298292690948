import { WizardTypes, FollowupWizardContext } from 'app/shared/models';
import { Actions as ProductWizardActions } from 'app/state/actions/product-wizard.actions';
import { Actions as FollowupWizardActions, Type as FollowupWizardActionType } from 'app/state/actions/followup-wizard.actions';
import { handleProductWizardActions } from './product-wizard-helpers';
export const initialState: FollowupWizardContext = {
    activeStep: 0,
    productId: null,
    groupResultInfo: { groupId: null, groupName: null, groupResultId: null, snapshotId: null, snapshotName: null },
    groupId: null,
    selectedRecommendation: null,
    selectedCategory: { key: null, name: null },
    selectedSubCategory: null,
    productDefinition: null,
    wizardType: WizardTypes.followup,
    nows: [],
    goals: [],
    actions: [],
    recommendations: [],
    saving: false,
    unsaved: false,
    currentVersion: null
};

export function reducer(state: FollowupWizardContext = initialState, action: FollowupWizardActions | ProductWizardActions) {

    const context = handleProductWizardActions(state, action, WizardTypes.followup, initialState);
    if (context !== null) {
        return context as FollowupWizardContext;
    }

    switch (action.type) {
        case FollowupWizardActionType.UNLOAD_FOLLOWUP_WIZARD: {
            return initialState;
        }
        case FollowupWizardActionType.SELECTED_RECOMMENDATION: {
            return { ...state, selectedRecommendation: action.payload };
        }
        case FollowupWizardActionType.SELECTED_RECOMMENDATION_CATEGORY: {
            return { ...state, selectedCategory: action.payload };
        }
        case FollowupWizardActionType.SELECTED_RECOMMENDATION_SUBCATEGORY: {
            return { ...state, selectedSubCategory: action.payload };
        }
        case FollowupWizardActionType.ADD_NOW: {
            const nows = [...state.nows];
            nows.push(action.payload);
            return { ...state, nows: nows };
        }
        case FollowupWizardActionType.EDIT_NOW: {
            const nows = [...state.nows];
            const index = nows.findIndex(f => f.tempId === action.payload.tempId);
            return { ...state, nows: [...nows.slice(0, index), action.payload, ...nows.slice(index + 1)] };
        }
        case FollowupWizardActionType.REMOVE_NOW: {
            const index = state.nows.findIndex(f => action.payload.tempId === f.tempId);
            if (-1 === index) {
                return { ...state };
            }
            return { ...state, nows: [...state.nows.slice(0, index), ...state.nows.slice(index + 1)] };
        }
        case FollowupWizardActionType.ADD_GOAL: {
            const goals = [...state.goals];
            goals.push(action.payload);
            return { ...state, goals: goals };
        }
        case FollowupWizardActionType.EDIT_GOAL: {
            const goals = [...state.goals];
            const index = goals.findIndex(f => f.tempId === action.payload.tempId);
            return { ...state, goals: [...goals.slice(0, index), action.payload, ...goals.slice(index + 1)] };
        }
        case FollowupWizardActionType.REMOVE_GOAL: {
            const index = state.goals.findIndex(f => action.payload.tempId === f.tempId);
            if (-1 === index) {
                return { ...state };
            }
            return { ...state, goals: [...state.goals.slice(0, index), ...state.goals.slice(index + 1)] };
        }
        case FollowupWizardActionType.ADD_ACTION: {
            const actions = [...state.actions];
            actions.push(action.payload);
            return { ...state, actions: actions };
        }
        case FollowupWizardActionType.EDIT_ACTION: {
            const actions = [...state.actions];
            const index = actions.findIndex(f => (f.id !== -1 && f.id === action.payload.id) || f.tempId === action.payload.tempId);
            return { ...state, actions: [...actions.slice(0, index), action.payload, ...actions.slice(index + 1)] };
        }
        case FollowupWizardActionType.REMOVE_ACTION: {
            const index = state.actions.findIndex(f => (f.id !== -1 && f.id === action.payload.id) || f.tempId === action.payload.tempId);
            // Not found, return same reference.
            if (-1 === index) {
                return { ...state };
            }
            // Return clone of items before and clone of items after.
            return { ...state, actions: [...state.actions.slice(0, index), ...state.actions.slice(index + 1)] };
        }
        default: {
            return state;
        }
    }
}
