import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { DialogType } from './dialog.type';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  DialogType = DialogType;
  constructor(
    public dialogRef: DialogRef<unknown, ConfirmationDialogComponent>,
    @Inject(DIALOG_DATA)
    public data: {
      title: string | { key: string; value: string };
      body: string;
      trueOption: string;
      falseOption: string;
      type: string;
      translate: boolean;
      label: string;
      value: string;
      placeholder: string;
      translationParameter: string;
      description: string;
    }
  ) {}

  isTitleString(): boolean {
    return typeof this.data.title === 'string';
  }

  getTitle(): any {
    return this.isTitleString()
      ? (this.data.title as string)
      : (this.data.title as { key: string; value: string });
  }
}
