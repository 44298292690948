import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ConfirmationDialogService } from 'app/shared/confirmation-dialog/confirmation-dialog.service';
import { DialogType } from 'app/shared/confirmation-dialog/dialog.type';
import { ActivateSurvey, CompanyContext, CompanyProductDefinition, ProductDefinitionJson, ProductIndex, Rule, RuleWithModuleKey, SurveyCreate, SurveyMetaDto, SurveyModulePageGroup, SurveyProductDefinitionJsonStep, SurveyWizardContext, UserWithRelatedUsers, WizardTypes, isSurveyWizardContext } from 'app/shared/models';
import { QuestionInfo } from 'app/shared/models/index.model';
import { ValidationData, ValidationError } from 'app/shared/product-wizard/survey/module-selection/models/survey-wizard-question.model';
import { CompanyProductDefinitionService, ErrorsService, GroupService, ModuleService, ProductService, SurveyService } from 'app/shared/services';
import { UserService } from 'app/shared/services/user.service';
import * as NavigationActions from 'app/state/actions/navigation.actions';
import * as ProductWizardActions from 'app/state/actions/product-wizard.actions';
import * as SurveyWizardActions from 'app/state/actions/survey-wizard.actions';
import { AppState } from 'app/state/app.state';
import { EMPTY, combineLatest, from, iif, of } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay, switchMap, take, withLatestFrom } from 'rxjs/operators';

export type scheduleActions =
  SurveyWizardActions.SetStartDate
  | SurveyWizardActions.SetStartTime
  | SurveyWizardActions.SetEndDate
  | SurveyWizardActions.SetReportDate
  | SurveyWizardActions.SetReminderEmail
  | SurveyWizardActions.SetReminderEmails
  | SurveyWizardActions.SetAutoReport;

export type communicationActions =
  SurveyWizardActions.SetActivationEmail
  | SurveyWizardActions.SetReportEmail
  | SurveyWizardActions.SetActivationSms
  | SurveyWizardActions.SetWelcomeSms
  | SurveyWizardActions.SetCommunicationEnabled
  | SurveyWizardActions.SetCommunicationMethod
  | SurveyWizardActions.SetPinCodeEmail
  | SurveyWizardActions.SetPinCodeSms
  | SurveyWizardActions.SetPincodeLanguage;

@Injectable()
export class SurveyWizardEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private groupService: GroupService,
    private productService: ProductService,
    private surveyService: SurveyService,
    private errorService: ErrorsService,
    private translations: TranslocoService,
    private companyProductDefinitionService: CompanyProductDefinitionService,
    private moduleService: ModuleService,
    private userService: UserService,
    private route: ActivatedRoute,
    private dialogService: ConfirmationDialogService) {
  }


  getIndexInfoOnNew$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SelectProductDefinitionSuccess>(ProductWizardActions.Type.SELECT_PRODUCT_DEFINITION_SUCCESS),
    filter(action => action.wizardType === WizardTypes.survey),
    withLatestFrom(this.store.select(s => s.surveyWizardContext), this.store.select(s => s.companyContext)),
    switchMap(([_, surveyWizardContext, companyContext]) => {

      if (surveyWizardContext.index?.indexes == null) {
        return from([new SurveyWizardActions.GetIndexInfo()])
      } else {
        return from([new NavigationActions.Go({ path: this.pathToSurveySetup(companyContext, surveyWizardContext.productId), extras: { state: { bypassFormGuard: true } } })])
      }
    })
  ));

  startFlow$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SetWizardState>(ProductWizardActions.Type.SET_WIZARD_STATE),
    filter(action => isSurveyWizardContext(action.payload)),
    withLatestFrom(this.store.select(s => s.companyContext), this.store.select(s => s.userInfo)),
    switchMap(([action, companyContext, userInfo]) => {
      const context = action.payload as SurveyWizardContext;
      const indexValidation = this.validateIndexes(context);
      const autoReportDefaultValue = !userInfo.userPermission.can('Survey.Create.NoCommunication', companyContext.id, -1);

      return from(
        [
          new SurveyWizardActions.SetIndexes(indexValidation),
          new SurveyWizardActions.InitAutoReport({ context: action.payload, autoReportDefaultValue }),
          new SurveyWizardActions.SetupContent({
            companyProductDefinition: action.payload.productDefinition,
            selectedModules: context.modules
          })
        ]);
    })
  ));

  startFlowGo$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SetWizardState>(ProductWizardActions.Type.SET_WIZARD_STATE),
    filter(action => isSurveyWizardContext(action.payload)),
    withLatestFrom(this.store.select(s => s.companyContext), this.store.select(s => s.surveyWizardContext)),
    switchMap(([action, companyContext, surveyWizardContext]) => {
      if (!(surveyWizardContext.wasDeactivated) && surveyWizardContext.surveyId) {
        return from(EMPTY);
      }
      return from([new NavigationActions.Go({ path: this.pathToSurveySetup(companyContext, action.payload.productId), extras: { state: { bypassFormGuard: true } } })])
    })
  ));

  setupContent$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.SetupContent>(SurveyWizardActions.Type.SETUP_CONTENT),
    withLatestFrom(this.store.select(s => s.companyContext)),
    switchMap(([action, companyContext]) => {

      const def: CompanyProductDefinition = action.payload.companyProductDefinition;
      const definitionObject: ProductDefinitionJson = def?.definitionObject ?? JSON.parse(def.definition) as ProductDefinitionJson;
      const moduleStep: SurveyProductDefinitionJsonStep = (definitionObject.steps as SurveyProductDefinitionJsonStep[]).find(s => s.stepId === 'modules');

      const moduleTags = moduleStep?.availableModuleTags ?? [];
      const moduleKeys = moduleStep?.availableModuleKeys ?? [];
      const preselected = moduleStep?.preselected ?? [];
      const main = moduleStep?.main ?? preselected[0];

      return iif(() => (moduleTags?.length > 0 || moduleKeys?.length > 0),
        this.moduleService.getByKeysAndTags(companyContext.id, moduleKeys, moduleTags).pipe(
          switchMap(response => {
            const moduleCategories = this.moduleService.getModuleCategories(response.modules);

            const selected = action.payload.selectedModules.map(sm => sm.key);
            if (selected.length === 0) {
              const preselectedModules = this.moduleService.getSelectedModules(preselected.filter((el) => !selected.includes(el)), moduleCategories);
              for (const preselectedModule of preselectedModules) {
                this.store.dispatch(new SurveyWizardActions.ToggleModule(preselectedModule));
              }
            }

            const mainModule = this.moduleService.getMainModule(main, moduleCategories);
            const mainModuleView = action.payload.selectedModules.find(sm => sm.key === mainModule?.key);

            if (mainModuleView) {
              outer:
              for (const moduleCategory of moduleCategories) {
                for (const module of moduleCategory.modules) {
                  if (module.key === mainModule.key) {
                    module.main = true;
                    break outer;
                  }
                }
              }
            }

            return from([new SurveyWizardActions.SetupContentSuccess(moduleCategories)]);
          })),
        from([new SurveyWizardActions.SetupContentSuccess([])]));
    }
    )));

  toggleGroups$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.ToggleGroup>(SurveyWizardActions.Type.TOGGLE_GROUP),
    withLatestFrom(this.store.select(s => s.surveyWizardContext)),
    switchMap(([action, surveyWizardContext]) => {
      const currentGroups = [...surveyWizardContext.participants.groups];
      if (currentGroups.some(x => x.id === action.payload.id)) {
        currentGroups.removeEvery(x => x.id === action.payload.id);
      } else {
        currentGroups.push(action.payload);
      }
      return from([new SurveyWizardActions.SetGroups(currentGroups)]);
    })
  ));

  updateParticipantCounts$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.SetGroups>(SurveyWizardActions.Type.SET_GROUPS),
    withLatestFrom(this.store.select(state => state.surveyWizardContext?.productDefinition)),
    switchMap(([action, productDefinition]) => {
      const definitionObject: ProductDefinitionJson = productDefinition?.definitionObject ?? JSON.parse(productDefinition.definition) as ProductDefinitionJson;
      const ignoreExcludeFlag = definitionObject.survey?.ignoreExcludedFlag ?? false;
      const settings = definitionObject.steps.find(s => s.stepId === 'participants')?.settings;
      const showPerspectives = (settings && 'showPerspectives' in settings) ? !!settings.showPerspectives : false;

      const companyId$ = this.store.select(s => s.companyContext.id).pipe(take(1), shareReplay(1));
      const participants$ = this.store.select(s => s.surveyWizardContext.participants.groups);
      const selectedHierarchyId$ = this.store.select(s => s.surveyWizardContext.participants?.selectedHierarchy?.id);

      return participants$.pipe(withLatestFrom(combineLatest([selectedHierarchyId$, companyId$])))
        .pipe(
          filter(([_, [hierarchyId, __]]) => !!hierarchyId),
          switchMap(([groups, [hiererchyId, companyId]]) => {
            return this.userService.getCompanyGroupsUsersPerspectives(companyId, hiererchyId, [...groups.map(g => g.id)])
          }),
          map((model: UserWithRelatedUsers[]) => {
            return showPerspectives
              ? {
                perspectivesRespondentsCount: +model?.reduce((prev, next) => (prev + (+next.userCount)), 0),
                perspectivesFocusUsersCount: +model?.length
              }
              : {}
          }),
          mergeMap(perspectiveModel => {
            return this.groupService.getUserCounts(action.payload.map(g => g.id), action.payload.filter(g => g.pin).map(g => g.id), ignoreExcludeFlag)
              .pipe(map(model => new SurveyWizardActions.SetParticipantsCounts({ ...model, ...perspectiveModel })))
          })
        )
    }))
  );

  activeSurvey$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.CreateProduct>(ProductWizardActions.Type.CREATE_PRODUCT),
    filter(action => action.payload.wizardType === WizardTypes.survey),
    withLatestFrom(this.store.select(s => s.companyContext)),
    switchMap(([action, companyContext]) => {
      const payload = action.payload as SurveyWizardContext;
      return this.surveyService.activate(
        companyContext.id,
        payload.surveyId,
        this.mapContextToSurveyActive(payload))
        .pipe(map(() => new ProductWizardActions.ProductCreated(payload.surveyId, WizardTypes.survey)));
    })
  ));

  saveDraftFromSurveyWizard$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SaveDraft>(ProductWizardActions.Type.SAVE_DRAFT),
    filter(action => action.payload.wizardType === WizardTypes.survey),
    withLatestFrom(this.store.select(s => s.companyContext)),
    switchMap(([action, context]) => {
      let payload = action.payload as SurveyWizardContext;
      this.store.dispatch(new ProductWizardActions.SetChanged({ changed: false }));
      return iif(() => !payload.surveyId,
        this.createSurvey(payload, context).pipe(
          switchMap(httpResult => {
            const surveyId = JSON.parse(httpResult.body).id;
            if (httpResult.ok) {
              payload = { ...payload, surveyId: surveyId, unsaved: false };
            }
            this.store.dispatch(new SurveyWizardActions.SetSurveyId(surveyId));
            return this.updateDraft(payload, JSON.stringify(payload), action.showMessage);
          })
        ),
        this.updateSurvey(payload, context).pipe(
          switchMap(_ => from([
            new SurveyWizardActions.SortModules(payload.modules)])),
          switchMap(() =>
            this.updateDraft({ ...payload, unsaved: false }, JSON.stringify(payload), action.showMessage))
        )
      );
    })
  ));

  saveDraftAndGoBackFromSurveyWizard$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SaveDraft>(ProductWizardActions.Type.SAVE_DRAFT_GO_BACK),
    filter(action => action.payload.wizardType === WizardTypes.survey),
    withLatestFrom(this.store.select(s => s.companyContext)),
    switchMap(([action, context]) => {
      let payload = action.payload as SurveyWizardContext;
      return iif(() =>
        !(payload).surveyId,
        this.createSurvey(payload, context).pipe(
          switchMap(httpResult => {
            const surveyId = JSON.parse(httpResult.body).id;
            if (httpResult.ok) {
              payload = { ...payload, surveyId: surveyId };
            }
            this.store.dispatch(new SurveyWizardActions.SetSurveyId(surveyId));
            return this.updateDraft(payload, JSON.stringify(payload), action.showMessage)
              .pipe(
                withLatestFrom(this.store.select(s => s.companyContext)),
                switchMap(([_, companyContext]) => from([
                  new ProductWizardActions.SetChanged(
                    {
                      changed: false,
                      action: new NavigationActions.Go({ path: [companyContext.shortName, 'surveys'] })
                    }),
                ]))
              );
          })
        ),
        this.updateSurvey(payload, context).pipe(
          switchMap(() => this.updateDraft(payload, JSON.stringify(payload), action.showMessage)),
          withLatestFrom(this.store.select(s => s.companyContext)),
          switchMap(([_, companyContext]) => from([
            new ProductWizardActions.SetChanged(
              {
                changed: false,
                action: new NavigationActions.Go({ path: [companyContext.shortName, 'surveys'] })
              }),
          ])
          )
        ));
    })
  ));

  SetPage$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.SetPage>(ProductWizardActions.Type.SET_PAGE),
    filter(action => action.wizardType === WizardTypes.survey),
    withLatestFrom(this.store.select(s => s.surveyWizardContext)),
    switchMap(([_, surveyWizardContext]) => iif(
      () => surveyWizardContext.unsaved,
      from([new ProductWizardActions.SaveDraft(surveyWizardContext)]),
      from(EMPTY))
    )
  ));

  productCreated$ = createEffect(() => this.actions$.pipe(
    ofType<ProductWizardActions.ProductCreated>(ProductWizardActions.Type.PRODUCT_CREATED),
    filter(action => action.wizardType === WizardTypes.survey),
    withLatestFrom(this.store.select(s => s.companyContext)),
    switchMap(([_, companyContext]) =>
      from([new NavigationActions.Go({ path: [companyContext.shortName, 'surveys'] })])
    )));

  getIndexInfo$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.GetIndexInfo>(SurveyWizardActions.Type.GET_INDEX_INFO),
    withLatestFrom(this.store.select(s => s.surveyWizardContext)),
    switchMap(([_, context]) => this.companyProductDefinitionService.getIndexInfoVerbose(context.productDefinition.id)),
    switchMap(indexes => of(new SurveyWizardActions.GetIndexInfoSuccess(indexes)))
  ));

  getIndexInfoSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.GetIndexInfoSuccess>(SurveyWizardActions.Type.GET_INDEX_INFO_SUCCESS),
    withLatestFrom(this.store.select(s => s.surveyWizardContext), this.store.select(s => s.companyContext)),
    switchMap(([_, surveyWizardContext, companyContext]) => {
      return from(surveyWizardContext.productId
        ? [new NavigationActions.Go({ path: this.pathToSurveySetup(companyContext, surveyWizardContext.productId) })]
        : [new ProductWizardActions.CreateDraft(surveyWizardContext)])
    }
    )
  ));

  calculateSurveyIndexes$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.ToggleModule>(SurveyWizardActions.Type.TOGGLE_MODULE, SurveyWizardActions.Type.TOGGLE_QUESTION),
    withLatestFrom(this.store.select(s => s.surveyWizardContext)),
    switchMap(([_, surveyWizardContext]) => {
      const indexValidation = this.validateIndexes(surveyWizardContext);
      return from([
        new SurveyWizardActions.SortModules(surveyWizardContext.modules),
        new SurveyWizardActions.SetIndexes(indexValidation),
      ]);
    })
  ));

  sortModules$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.SortModules>(SurveyWizardActions.Type.SORT_MODULES),
    switchMap(x => from([]))
  ));

  // TODO: This is due to communication component dispatching action upon init
  setCommunicationMethod$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.SetCommunicationMethod>(SurveyWizardActions.Type.SET_COMMUNICATION_METHOD),
    switchMap((action) => {
      if (!!action.init) { return from(EMPTY) }
      return from([new SurveyWizardActions.SetCommunicationMethodSuccess(action.payload)]);
    })
  ));

  setName$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.SetName>(SurveyWizardActions.Type.SET_NAME),
    withLatestFrom(this.store.select(s => s.surveyWizardContext.name)),
    switchMap(([action, name]) => {
      if (action.payload != name)
        return from([new SurveyWizardActions.SetNameSuccess(action.payload)]);
      return from(EMPTY);
    })
  )
  );

  setChanged$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.SetNameSuccess
      | SurveyWizardActions.AddQuestionsToModule
      | SurveyWizardActions.SetGroups
      | SurveyWizardActions.ToggleQuestion
      | communicationActions | scheduleActions>(
        SurveyWizardActions.Type.SET_NAME_SUCCESS,
        SurveyWizardActions.Type.ADD_QUESTIONS_TO_MODULE,
        SurveyWizardActions.Type.SET_GROUPS,
        SurveyWizardActions.Type.TOGGLE_QUESTION,
        SurveyWizardActions.Type.SET_ACTIVATION_EMAIL,
        SurveyWizardActions.Type.SET_REPORT_EMAIL,
        SurveyWizardActions.Type.SET_ACTIVATION_SMS,
        SurveyWizardActions.Type.SET_WELCOME_SMS,
        SurveyWizardActions.Type.SET_COMMUNICATION_METHOD_SUCCESS,
        SurveyWizardActions.Type.SET_PIN_CODE_EMAIL,
        SurveyWizardActions.Type.SET_PIN_CODE_SMS,
        SurveyWizardActions.Type.SET_PINCODE_LANGUAGE,
        SurveyWizardActions.Type.SET_START_DATE,
        SurveyWizardActions.Type.SET_START_TIME,
        SurveyWizardActions.Type.SET_END_DATE,
        SurveyWizardActions.Type.SET_REPORT_DATE,
        SurveyWizardActions.Type.SET_REMINDER_EMAIL,
        SurveyWizardActions.Type.SET_REMINDER_EMAILS,
        SurveyWizardActions.Type.SET_AUTO_REPORT
      ),
    withLatestFrom(this.store.select(s => s.surveyWizardContext)),
    switchMap(([_, context]) => {
      if (!context.unsaved) {
        return from([new ProductWizardActions.SetChanged({ changed: true })]);
      }
      return from(EMPTY)
    })
  ));

  setChangedForToggleModule$ = createEffect(() => this.actions$.pipe(
    ofType<SurveyWizardActions.ToggleModule>(SurveyWizardActions.Type.TOGGLE_MODULE),
    withLatestFrom(this.store.select(s => s.surveyWizardContext)),
    switchMap(([tm, context]) => {
      if (!context.unsaved && !tm.payload.preSelected) {
        return from([new ProductWizardActions.SetChanged({ changed: true })]);
      }
      return from(EMPTY)
    })
  ));

  private validateIndexes(context: SurveyWizardContext): ValidationData[] {
    const validationTable: ValidationData[] = [];

    const requiredIndexes = context.index.indexInfo.filter(idx => idx.required).map(x => x.id);
    const selectedQuestions: string[] = context.modules
      .flatMap(module => module.pageGroups
        .flatMap(pageGroup => pageGroup.pages
          .flatMap(pages => pages.pageItems.filter(pageItem => !!pageItem.questionId && !pageItem.excluded))).map(pageItem => pageItem.key));

    context.index.indexInfo.forEach(index => {
      const data = this.validateIndex(index, null, requiredIndexes, selectedQuestions);
      validationTable.push(data);
    });
    return validationTable;
  }

  private validateIndex(index: ProductIndex, parentId: unknown, requiredIndexes: number[], selectedQuestions: string[]): ValidationData {

    const childData: ValidationData[] = [];
    if (index.children && index.children.length > 0) {
      for (let i = 0; i < index.children.length; i++) {
        childData.push(this.validateIndex(index.children[i], index.id, requiredIndexes, selectedQuestions));
      }
    }

    const additionalQuestions: QuestionInfo[] = [
      ...(index.questionKeys).filter(qk => !selectedQuestions.includes(qk.key)),
      ...childData.flatMap(x => x.additionalQuestions)
    ];

    const includedQuestions =
      (index.questionKeys).filter(qk => selectedQuestions.includes(qk.key));
    const childrenIncludedQuestions = childData.map(c => c.includedQuestions.length)
      .reduce((prev, curr) => prev + curr, 0);
    const includedQuestionsCount = includedQuestions ? includedQuestions.length : childrenIncludedQuestions;

    const childQuestionCount = index.children.map(c => c.questionKeys.length)
      .reduce((prev, curr) => prev + curr, 0);
    const indexAvailableQuestionsCount = index.questionKeys ? index.questionKeys.length
      : childQuestionCount;

    const childrenMinQ = childData.map(c => c.minQuestionCount)
      .reduce((prev, curr) => prev + curr, 0);
    const childrenMaxQ = childData.map(c => c.maxQuestionCount)
      .reduce((prev, curr) => prev + curr, 0);
    const minQuestionCount = index.minQuestionCount ? index.minQuestionCount + childrenMinQ
      : (childrenMinQ ? childrenMinQ : index.questionKeys.length);
    const maxQuestionCount = index.maxQuestionCount ? index.maxQuestionCount + childrenMaxQ
      : (childrenMaxQ ? childrenMaxQ : index.questionKeys.length);

    let errors = ValidationError.None;
    let status = 'INSIGHT.ADMIN.PRODUCTWIZARD.VALIDATION.OK';
    /*
    if (
        !(childData.map(c => c.errors).includes(ValidationError.Error))
        && !(requiredIndexes.includes(index.id) || requiredIndexes.includes(index.parentId))) {
        errors = ValidationError.Warning;
        status = 'INSIGHT.ADMIN.PRODUCTWIZARD.VALIDATION.NOT_IN_PRODUCT';
    }*/

    if (includedQuestionsCount < minQuestionCount
      || childData.filter(i => i.errors !== ValidationError.None).length > 0) {
      if (index.required) {
        errors = ValidationError.Error;
        status = 'INSIGHT.ADMIN.PRODUCTWIZARD.VALIDATION.NEEDS_QUESTIONS';
      } else if (childData.findIndex(i => i.errors !== ValidationError.None) > -1) {
        const data = childData.find(i => i.errors !== ValidationError.None);
        errors = data.errors;
        status = data.status;
      } else {
        errors = ValidationError.Warning;
        status = 'INSIGHT.ADMIN.PRODUCTWIZARD.VALIDATION.CANNOT_BE_CALCULATED';
      }
    }
    return {
      parentId: +parentId,
      id: index.id,
      key: index.indexKey,
      children: childData,
      name: index.name,
      minQuestionCount: minQuestionCount,
      maxQuestionCount: maxQuestionCount,
      indexAvailableQuestionsCount: indexAvailableQuestionsCount,
      additionalQuestions: additionalQuestions,
      questionCount: includedQuestionsCount,
      includedQuestions: includedQuestions,
      required: index.required,
      errors: errors,
      status: status,
    };
  }

  private updateDraft(payload: SurveyWizardContext, draft: string, showMessage: boolean) {
    return this.productService.updateDraft(payload.productId, { draft: draft, draftVersion: payload.currentVersion, checkVersion: true }).pipe(
      map(result => {
        const currentVersion = JSON.parse(result.body).currentVersion;
        return new ProductWizardActions.DraftSaved({ productId: payload.productId, currentVersion: currentVersion }, payload.wizardType, showMessage);
      }),
      catchError(err => {
        if (err?.error === '"Outdated version"') {
          return this.productService.getVersion(payload.productId)
            .pipe(switchMap((response) => {
              return this.dialogService.openDialog<boolean>(
                'INSIGHT.ADMIN.PRODUCTWIZARD.VERSION_WARNING.TITLE_WARNING',
                'INSIGHT.ADMIN.PRODUCTWIZARD.VERSION_WARNING.BODY_WARNING',
                500,
                DialogType.Confirm,
                'INSIGHT.ADMIN.PRODUCTWIZARD.VERSION_WARNING.CONTINUE',
                'INSIGHT.ADMIN.PRODUCTWIZARD.VERSION_WARNING.CANCEL',
                true,
                null,
                true,
                '',
                `${response.lastUpdatedBy ?? 'N/A'} ${response.lastUpdatedDate ? new Date(response.lastUpdatedDate).toLocaleString() : ''}`
              )
                .pipe(
                  switchMap(response => {
                    if (response) {
                      return this.productService.updateDraft(payload.productId, { draft: draft, draftVersion: payload.currentVersion, checkVersion: false }).pipe(
                        map(httpResult => {
                          const currentVersion = JSON.parse(httpResult.body).currentVersion;
                          return new ProductWizardActions.DraftSaved({ productId: payload.productId, currentVersion: currentVersion }, payload.wizardType, showMessage)
                        }
                        ));
                    }
                    else {
                      this.store.dispatch(new NavigationActions.Go({ path: [this.store.select(s => s.companyContext.shortName), 'surveys'], extras: { state: { bypassFormGuard: true } } }));
                    }
                  }));
            }))
        }
      }))
  }

  private createSurvey(payload: SurveyWizardContext, context: CompanyContext) {
    return this.surveyService.create(
      context.id,
      this.mapContextToSurveyCreate(context.id, payload)).pipe(
        catchError(err => {
          this.translations.selectTranslate('INSIGHT.GLOBAL.ERROR.SOMETHINGWRONG')
            .pipe(take(1))
            .subscribe((msg: string) => {
            this.errorService.openErrorPopup(msg);
          });
          return of(err);
        }))
  }

  private updateSurvey(payload: SurveyWizardContext, context: CompanyContext) {
    return this.surveyService.update(
      context.id,
      payload.surveyId,
      this.mapContextToSurveyCreate(context.id, payload)).pipe(
        catchError(err => {
          this.translations.selectTranslate('INSIGHT.GLOBAL.ERROR.SOMETHINGWRONG')
            .pipe(take(1))
            .subscribe((msg: string) => {
            this.errorService.openErrorPopup(msg);
          });
          return of(err);
        }));
  }

  private mapContextToSurveyCreate(companyId: number, context: SurveyWizardContext): SurveyCreate {

    const rules: RuleWithModuleKey[] = context.modules
      .flatMap(m => (m.rules
        .map((r: Rule): RuleWithModuleKey => ({ ...r, moduleKey: m.key }))));

    const emailId = context.communication.communicationMethod === 'email' ? context.communication?.activationEmail?.id : null;
    const pinCodeEmailId = context.communication.communicationMethod === 'email' && context.communication?.hasPinGroups ?
      context.communication?.pinCodeEmail?.id : null;

    const meta: SurveyMetaDto[] = [];
    const pageGroups: SurveyModulePageGroup[] = [];
    context.modules.forEach(function (m) {
      meta.push(...m.meta.map(mm => ({ metaId: mm.metaId, value: mm.value, moduleKey: m.key })));
      pageGroups.push(...m.pageGroups.map((pg): SurveyModulePageGroup => ({ ...pg, moduleId: m.id, moduleKey: m.key })))
    });

    return {
      companyId: companyId,
      productId: context.productId,
      name: context.name,
      startDate: context.schedule.startDate,
      endDate: context.schedule.endDate,
      groups: context.participants.groups.map(x => x.id),
      indexes: context.index.indexes,
      emailId: emailId,
      pinCodeEmailId: pinCodeEmailId,
      reminderEmails: emailId ? context.schedule.reminderEmails : [],
      pageGroups: pageGroups,
      rules: rules,
      ruleIds: rules.map(x => +x.id),
      testMode: true,
      meta: meta,
      multipleSessions: context.productDefinition.definitionObject.survey?.multipleSessions ?? false
    };
  }

  private mapContextToSurveyActive(context: SurveyWizardContext): ActivateSurvey {
    const pageGroups: SurveyModulePageGroup[] = [];
    context.modules.forEach(module => {
      pageGroups.push(...module.pageGroups.map(pg => ({ ...pg, moduleId: module.id, moduleKey: module.key })))
    });
    const rules = context.modules.reduce(((pre: Rule[], nex) => [...pre, ...nex.rules]), []);
    const emailId = context.communication.communicationMethod === 'email' ?
      context.communication.activationEmail?.id : null;
    const pinCodeEmailId = context.communication.communicationMethod === 'email' && context.communication.hasPinGroups ?
      context.communication.pinCodeEmail?.id : null;
    const reportEmailId = context.communication.communicationMethod === 'email' ?
      context.communication.reportEmail?.id : null;

    const activationSmsId = context.communication.communicationMethod === 'email' ?
      context.communication.activationSms?.id : null;
    const pinCodeSmsId = context.communication.communicationMethod === 'email' &&
      context.communication.hasPinGroups ?
      context.communication.pinCodeSms?.id : null;
    const welcomeSmsId = context.communication.communicationMethod === 'email' ?
      context.communication.welcomeSms?.id : null;

    return {
      productId: context.productId,
      name: context.name,
      startDate: context.schedule.startDate,
      endDate: context.schedule.endDate,
      reportDate: context.schedule.reportDate,
      hierarchyId: context.participants.selectedHierarchy?.id,
      groups: context.participants.groups.map(x => x.id),
      users: [],
      indexes: context.index.indexes,
      emailId: emailId,
      pinCodeEmailId: pinCodeEmailId,
      reportEmailId: reportEmailId,
      activationSmsId: activationSmsId,
      pinCodeSmsId: pinCodeSmsId,
      welcomeSmsId: welcomeSmsId,
      reminderEmails: emailId ? context.schedule.reminderEmails : [],
      autoReport: context.communication.autoReport,
      pageGroups: pageGroups,
      rules: rules.map(x => x.id),
      directActivation: context.schedule.directActivation,
      multipleSessions: context.productDefinition.definitionObject.survey.multipleSessions
    };

  }

  private activatedRoute() {
    let route: ActivatedRoute = this.route;
    while (route.firstChild != null) {
      route = route.firstChild;
    }
    return route;
  }

  private pathToSurveySetup(companyContext: CompanyContext, productId: number) {
    return [companyContext.shortName, 'surveys', 'drafts', 'setup', productId];
  }
}

