import {
  Condition,
  ContentDetailed,
  ModuleMeta,
  ModulePageGroup,
  Name,
  RuleTarget
} from 'app/shared/models';
import { ModuleView } from '../product-wizard/survey/module-selection/models';
export interface Module {
  category: ModuleCategory;
  description: ContentDetailed;
  icon?: string;
  id: number;
  imageUrl?: string;
  indexKeys: string[];
  key: string;
  languageId: number;
  libraryId: number;
  isActive: boolean;
  isActiveInCore: boolean;
  isActiveOverrideEnabled: boolean;
  libraryCompanyId: number;
  masterLanguageId: number;
  meta: ModuleMeta[];
  moduleCategoryId: number;
  moduleTypeId: number;
  name: ContentDetailed;
  translatedName?: string;
  names: Name[];
  nameDescriptions: ModuleNameDescription[];
  order: number;
  pageGroups: ModulePageGroup[];
  preSelected?: boolean;
  questionPriority: number;
  readonly: boolean;
  rules: Rule[];
  totalQuestions: number;
}

export interface ModuleNameDescription {
  descriptionId: number;
  descriptionName: string;
  descriptionText: string;
  languageId: number;
  nameId: number;
  nameName: string;
  nameText: string;
}

export interface ModuleType {
  id: number;
  name: string;
}

export interface ModuleCategory {
  id?: number;
  key: string;
  name?: string;
  order: number;
  text?: string;
}

export interface CreateModuleCategory {
  id?: number;
  key: string;
  name: ContentDetailed;
  order: number;
  text: ContentDetailed;
}

export interface ModuleCategoryGroup {
  category: ModuleCategory;
  modules: ModuleView[];
}

export enum ExecutionMode {
  default = 'default',
  includeTargetAsInput = 'includeTargetAsInput'
}

export interface Rule {
  action: string;
  actionId: number;
  condition: Condition;
  executionMode?: string;
  id: number;
  targets: RuleTarget[];
}
export interface RuleWithModuleKey extends Rule {
  moduleKey: string;
}
export interface ModuleLight {
  LibraryAndModuleName: string;
  libraryId: number;
  libraryName: string;
  moduleId: number;
  name: string;
}

export interface ModuleWithQuestionsAndTranslations {
  text: string;
  questions: ModuleQuestionWithTranslations[];
}

export interface ModuleQuestionWithTranslations {
  key: string;
  questionText: ModuleQuestionTranslation[];
  responseScale: string;
}

export interface ModuleQuestionTranslation {
  helpText: string;
  key: string;
  language: string;
  question: string;
  responseScale: string;
}

export enum QuestionPriority {
  High = 0,
  Medium = 10,
  Low = 20
}
