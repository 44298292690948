import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateRole, Role } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RoleService {
  roles$: Observable<Role[]>;

  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }
  getRoles(): Observable<Role[]> {
    if (!!this.roles$) return this.roles$;
    this.roles$ = this._http.get<Role[]>(`${this._config.apiUrl}/api/roles`);
    return this.roles$;
  }

  getByCompany(_: number): Observable<Role[]> {
    return this.getRoles();
  }

  get(roleId: number): Observable<Role> {
    return this._http.get<Role>(`${this._config.apiUrl}/api/roles/${roleId}`);
  }

  updateRole(role: Role): Observable<HttpResponse<any>> {
    return this._http.put(`${this._config.apiUrl}/api/roles/${role.id}`,
      role,
      { observe: 'response', responseType: 'text' });
  }

  createRole(role: CreateRole): Observable<Role> {
    return this._http.post<Role>(`${this._config.apiUrl}/api/roles`, role);
  }

  deleteRole(roleId: number): Observable<void> {
    return this._http.delete<void>(`${this._config.apiUrl}/api/roles/${roleId}`);
  }
}
