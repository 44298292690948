import { createSelector } from '@ngrx/store';
import { CompanyContext, Language } from 'app/shared/models';
import { AppState } from 'app/state/app.state';

export const selectCompanyContext = (state: AppState) => state.companyContext;

export const companyIdSelect = (state: AppState) => state.companyContext.id;

export const companyShortNameSelect = (state: AppState) => state.companyContext.shortName;

export const companyAttributesSelect = (state: AppState) => state.companyContext.companyAttributes;

export const companyNameSelect = (state: AppState) => state.companyContext.name;

export const companyTypeSelect = (state: AppState) => state.companyContext.type;
export const companyBrandingSelect = createSelector(
  selectCompanyContext,
  (companyContext: CompanyContext) => companyContext.branding
);
export const displayLanguageSelect = (state: AppState) => state.userInfo.displayLanguageCode;
export const displayLanguageIdSelect = (state: AppState) => state.userInfo.displayLanguageId;

export const companyLanguagesSelect = createSelector(
  selectCompanyContext,
  (companyContext: CompanyContext) => companyContext.companyLanguages
);

export const companyDefaultLanguageSelect = createSelector(
  companyLanguagesSelect,
  (languages: Language[]) => languages.find(l => !!l.isDefaultCompanyLanguage)
)
