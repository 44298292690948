import { AttributeSlim } from 'app/shared/models';
import { Meta, ModuleMeta } from 'app/shared/models';
import { Content } from 'app/shared/models';
import { UserAttribute } from 'app/shared/models';
import { GroupAttribute } from 'app/shared/models';

export enum HierarchyType {
  comparison = 'comparison',
  report = 'report',
  organisation = 'organisation',
  surveywizard = 'surveywizard'
}

export interface TreeModelBase {
  id: number;
  name: string;
  categoryId: number;
  hierarchyId?: number;
}
export interface TreeModelBaseWithCategory {
  id: number;
  name: string;
  categoryId: number;
  category: string;
  hierarchyId?: number;
}

export interface ReportGroupModel extends TreeModelBaseWithCategory {
  managerNames: string;
}

export interface ConditionValue {
  source: string;
  sourceId: number;
  value: string;
}
export interface ComparisonGroupModel extends TreeModelBaseWithCategory {
  categoryName: string;
  conditionValues?: ConditionValue[];
  conditionHash?: string;
  parentId: number;
  filterId?: number;
}


export interface OrganisationGroupModel extends TreeModelBaseWithCategory {
  children: OrganisationGroupModel[];
  categoryName: string;
  externalId: string;
  userCount: number;
  managerNames: string;
  reportAccessUserNames: string;
  isPin: boolean;
  parentId?: number;
}

export interface SurveyGroupModel extends TreeModelBase {
  groupUserCount: number;
  participantCount: number;
  responseCount: number;
  responseRate: number;
}

export interface SurveySetupGroupModel extends TreeModelBaseWithCategory {
  categoryName: string;
  pin: boolean;
}

export interface HierarchyDto<T> {
  name: string;
  id: number;
  isDefault: boolean;
  tree: TreeGroupDto<T>[];
  flattenedTree?: FlattenedTreeModel<T>[];
}
export interface ComparisonBackgroundFilterTreeModel {
  comparisonGroups: HierarchyDto<ComparisonGroupModel>[];
  backgroundFilters: HierarchyDto<ComparisonGroupModel>;
}

export type FlattenedTreeModel<T> = T & { leaf?: boolean, hierarchyId: number, categorySortPriority: number, children: FlattenedTreeModel<T>[], parentId?: number };

export function flattenTreeModel<T>(hierarchy: HierarchyDto<T>) {

  const flattenModel = (treeGroup: TreeGroupDto<T>, hierarchyId: number): FlattenedTreeModel<T> => {
    return {
      ...treeGroup.model,
      hierarchyId: hierarchyId,
      categorySortPriority: treeGroup.categorySortPriority,
      leaf: treeGroup.leaf,
      children: treeGroup.children.map(c => flattenModel(c, hierarchyId)),
      parentId: treeGroup.parentId
    };
  };

  if (hierarchy.flattenedTree == null) {
    hierarchy.flattenedTree = hierarchy.tree.map(x => flattenModel(x, hierarchy.id));
  }
}

export interface TreeGroupDto<T> {
  groupId: number;
  categorySortPriority: number;
  model: T;
  leaf: boolean;
  children: TreeGroupDto<T>[];
  parentId?: number;
}

export interface Group {
  id: number;
  groupPath?: string;
  parentId?: number;
  externalId: string;
  companyId: number;
  name: string;
  category: string;
  categoryName: string;
  childCategory: string;
  userCount?: number;
  isPin?: boolean;
  meta?: ModuleMeta[];
  attributes?: GroupAttribute[];
  managerNames: string;
  deletedTime?: Date;
  groupCategoryConditionType: GroupCategoryConditionType;
}

export type HierarchyGroup<T> = FlattenedTreeModel<T> & {
  expandable: boolean;
  level: number;
};

export interface SortingOrganisation {
  data: FlattenedTreeModel<TreeModelBase>;
}

export interface SelectionGroup {
  id: number;
  name: string;
}
export interface WizardSelectionGroup extends SelectionGroup {
  pin?: boolean;
}

export interface GroupList {
  companyId: number;
  groupIds: number[];
}

export interface GroupCategoryList {
  id: number;
  key: string;
  name: string;
  customName: Content;
  nameId: number;
  conditionType: GroupCategoryConditionType;
  creatable: boolean;
  deletable: boolean;
  inheritAttributes: boolean;
  usersAddable: boolean;
  hideExternalId: boolean;
  allowActionPlan: boolean;
  participationRate: number;
  visibleRate: number;
  noCommonUserRelations: boolean;
  isInSecondaryHierarchy: boolean;
  groupRelationCategoryId?: number;
  attributes?: AttributeSlim[];
  isLeaf: boolean;
  companyId: number;
}

export interface EditGroupCategory {
  nameId?: number;
  categoryId: number;
  filterable: boolean;
  creatable: boolean;
  deletable: boolean;
  inheritAttributes: boolean;
  usersAddable: boolean;
  hideExternalId: boolean;
  allowActionPlan: boolean;
  participationRate?: number;
  visibleRate?: number;
  noCommonUserRelations: boolean;
  isInSecondaryHierarchy: boolean;
  order: number;
  conditionType: GroupCategoryConditionType;
  groupRelationCategoryId?: number;
  attributeDefinitionIds: number[];
}
export interface EditGroupCategoryModel extends EditGroupCategory {
  customName: Content;
}

export enum GroupCategoryConditionType {
  'None' = 'None',
  'Attribute' = 'Attribute',
  'GroupRelation' = 'GroupRelation',
  'UserRelation' = 'UserRelation',
  'Composite' = 'Composite',
  'Custom' = 'Custom',
  'GroupUser' = 'GroupUser'
}

export interface GroupCategory {
  value: string;
  text: string;
  parentCategories: ParentCategory[];
  categoryFilters: GroupCategoryFilter[];
}

export interface ParentCategory {
  text: string;
  value: string;
}

export interface GroupInfo {
  includeUserCount: boolean;
  includeMeta: boolean;
  defaultHierarchyOnly: boolean;
}

export interface ChrossGroupInfo {
  internalId: string;
  externalId: string;
  name: string;
  answerCount: number;
  userCount: number;
  insightName: string;
  insightId: number;
  insightMeta: string;
  insightExternalId: string;
  agg: number;
  match: boolean;
  dirty?: boolean;
}

export interface GroupCategoryFilter {
  category: string;
  groupFilters: GroupFilter[];
}

export interface GroupFilter {
  groupName: string;
  id: number;
  metaValues: string[];
}

export interface GroupCategoryRelation {
  fromCategory: string;
  toCategory: string;
  direction: string;
  reverse?: boolean;
}

export interface GroupCategorySelection {
  parentCategory: string;
  groups?: GroupFilter[];
  groupIds?: string[];
}

export interface GroupsByParent {
  category: string;
  dataSourceName: string;
  filters: GroupCategorySelection[];
}

export interface UserGroup {
  companyId: number;
  conditionId: number;
  externalId: string;
  id: number;
  manuallyEdited: boolean;
  meta: Meta[];
  name: string;
}

export interface HierarchyLevelChildrenViewModel {
  id: number;
  parentId: number;
  key: string;
  name: string;
  optional: boolean;
  usersAddable: boolean;
  createable: boolean;
  deletable: boolean;
  leafNode: boolean;
  createableViewModel: CreateableGroupCategory;
  children: CreateableGroupCategory[];
}

export interface CreateableGroupCategory {
  id: number;
  key: string;
  name: string;
  ruleId?: number;
  attributes: GroupAttribute[];
  managerHandledByRule: boolean;
  hideExternalId: boolean;
}

export interface GroupRequiredValues {
  attributes: UserAttribute[];
  languageId?: number;
}

export interface AllowedMoveCategories {
  hierarchyId: number;
  allowedMoves: Record<string, string[]>;
}

export interface AllowedAddUserCategories {
  hierarchyId: number;
  allowedCategories: Record<string, string[]>;
}
export interface GroupUsersCounts {
  userCount: number;
  nonPinCodeUserCount: number;
  usersWithPhone: number;
  usersWithEmail: number;
  perspectivesRespondentsCount?: number;
  perspectivesFocusUsersCount?: number;
}

export interface GroupWithSnapshot {
  id: number;
  name: string;
  externalId: string;
  snapshots: GroupSnapshot[];
}

export interface GroupSnapshot {
  id: number;
  name: string;
  datePeriod: string;
}

export interface MinimalHierarchicalGroup {
  children?: MinimalHierarchicalGroup[],
  id: number
}

export interface SelectedGroup {
  id: number;
  filterId?: number;
  name?: string;
}
